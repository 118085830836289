<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr class="text-center">
                <th rowspan="2" class="text-center my-border customlightgray">{{$t("Number of requests closed past the legislated timelines")}}</th>
                <th :colspan="Headers.length" class="text-center my-border customlightgray">
                    {{$t("Principal Reason")}}
                </th>
            </tr>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="my-border customoffwhite">{{ getNOR().length }}</td>
                <td class="my-border customoffwhite">{{getWORK(getNOR())}}</td>
                <td class="my-border customoffwhite">{{getEXTCONS(getNOR())}}</td>
                <td class="my-border customoffwhite">{{getINTCONS(getNOR())}}</td>
                <td class="my-border customoffwhite">{{getOTHER(getNOR())}}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapState , mapGetters} from "vuex";
import moment from "moment"
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return { 
        Headers:[
            {text:this.$t('Interference with operations/Workload'),value:'interf'},
            {text:this.$t('External consultation'),value:'ext'},
            {text:this.$t('Internal consultation'),value:'int'},
            {text:this.$t('Other'),value:'other'},
        ],
            
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
      ...mapState('configuration',{
        'configurations':'configurations'
      }),
      ...mapGetters({
        getTypes:"getTypes"
      }),
      
  },
  methods: {
    getNOR(){
        let r1 = this.requests.filter(item=>new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")) > moment(this.calculateDueDate(item)) &&
                                    new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() &&
                                    item.DateOfClosure!=null);
        return r1;
    },
    getWORK(reqs){
        return reqs.filter(item=>item.LateReason=='WORK').length
    },
    getEXTCONS(reqs){
        return reqs.filter(item=>item.LateReason=='EXTCONS').length
    },
    getINTCONS(reqs){
        return reqs.filter(item=>item.LateReason=='INTCONS').length
    },
    getOTHER(reqs){
        return reqs.filter(item=>item.LateReason=='OTHER').length
    },
     calculateDueDate(request){
      let requestType = this.getTypes( request.request_type );
      let cal = 0;
      cal =+ requestType?.time_frame_to_deliver || 0;
      cal =+ request.HoldDays;
      cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
      cal =+ request.ExtensionDays;
      let DueDate= moment(request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
      if(!requestType.editable_due_date && !request.DateDue)  
        return this.checkHolidayWeekend(DueDate);
      else
        return request.DateDue;
    },
    checkHolidayWeekend(date){
      let currentDay = moment(date).day();
      let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
      let totalHoliday = 0;
      if( holidays.length )
        totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
      if( currentDay === 6 || currentDay === 7 || totalHoliday ){
        if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
        if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
        this.checkHolidayWeekend(date);
      }
      return date;
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>