<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr class="text-center">
                    <th :colspan="Headers.length" class="text-center my-border customlightgray">
                        {{$t("Completion Time")}}
                    </th>
                </tr>
                <tr>
                    <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                        {{item.text}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.range1"
                >
                    <td class="my-border customoffwhite">{{ item.range1 }}</td>
                    <td class="my-border customoffwhite">{{ item.range16 }}</td>
                    <td class="my-border customoffwhite">{{ item.range31 }}</td>
                    <td class="my-border customoffwhite">{{ item.range61 }}</td>
                    <td class="my-border customoffwhite">{{ item.range121 }}</td>
                    <td class="my-border customoffwhite">{{ item.range181 }}</td>
                    <td class="my-border customoffwhite">{{ item.range365 }}</td>
                    <td class="my-border customoffwhite">{{ item.total }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
                { text: this.$t('1 to 15 days'), value: 'range1' },
                { text: this.$t('16 to 30 days'), value: 'range16' },
                { text: this.$t('31 to 60 days'), value: 'range31' },
                { text: this.$t('61 to 120 days'), value: 'range61' },
                { text: this.$t('121 to 180 days'), value: 'range121' },
                { text: this.$t('181 to 365 days'), value: 'range181' },
                { text: this.$t('More than 365 days'), value: 'range365' },
                { text: this.$t('Total'), value: 'total' },
            ],
        List:[
                {range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
            ],
            
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>