import { render, staticRenderFns } from "./Access_to_information_Act.vue?vue&type=template&id=1daae3c3&scoped=true&"
import script from "./Access_to_information_Act.vue?vue&type=script&lang=js&"
export * from "./Access_to_information_Act.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1daae3c3",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VTreeview } from 'vuetify/lib/components/VTreeview';
installComponents(component, {VCard,VCardText,VCol,VDivider,VIcon,VRow,VStepper,VStepperContent,VStepperItems,VTreeview})
