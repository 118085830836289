<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"
            :key="item.sec41"
            >     
                <td class="my-border customoffwhite">{{ item.sec41 }}</td>
                <td class="my-border customoffwhite">{{ item.sec42 }}</td>
                <td class="my-border customoffwhite">{{ item.sec44 }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
            {text:this.$t('Section 41 (Before C-58)'),value:'sec41'},
            {text:'Section 42',value:'sec42'}, 
            {text:'Section 44',value:'sec44'},
        ],
        List:[
                {sec41:'0',sec42:'0',sec44:'0',sec372:'0',sec373:'0'},
        ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>