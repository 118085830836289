<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"
            :key="item.nofd"
            >
                <td class="my-border customoffwhite">{{ item.nofd }}</td>
                <td class="my-border customoffwhite">{{ getNOR(item.period).length-getWithExt(getNOR(item.period)).length }}</td>
                <td class="my-border customoffwhite">{{ getWithExt(getNOR(item.period)).length }}</td>
                <td class="my-border customoffwhite">{{ getNOR(item.period).length }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapState , mapGetters } from "vuex";
import moment from "moment"
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return { 
        Headers:[
            {text:this.$t('Number of days past legislated timelines'),value:'nofd'},
            {text:this.$t('Number of requests past legislated timeline where no extension was taken'),value:'nofrwno'},
            {text:this.$t('Number of requests past legislated timeline where an extension was taken'),value:'nofrwan'},
            {text:this.$t('Total'),value:'total'},
        ],
        List:[
        ],
            
    }
  },
  mounted () {
        this.List=[
            {nofd:this.$t('1 to 15 days'),period:[1,15]},
            {nofd:this.$t('16 to 30 days'),period:[16,30]},
            {nofd:this.$t('31 to 60 days'),period:[31,60]},
            {nofd:this.$t('61 to 120 days'),period:[61,120]},
            {nofd:this.$t('121 to 180 days'),period:[121,180]},
            {nofd:this.$t('181 to 365 days'),period:[181,365]},
            {nofd:this.$t('More than 365 days'),period:[366,99999]},
            {nofd:this.$t('Total'),period:[1,99999]},]
  },
  async created(){ 
  },
  computed:{  
      ...mapState('configuration',{
        'configurations':'configurations'
      }),
      ...mapGetters({
      getTypes:"getTypes"
    }),
  },
  methods: {
    getNOR(period){
        let result = this.requests.filter(item=>new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")) > moment(this.calculateDueDate(item)) &&
                                    new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() &&
                                    (moment(item.DateOfClosure).diff(moment(this.calculateDueDate(item)), "days")) >= period[0] &&
                                    period[1] >= (moment(item.DateOfClosure).diff(moment(this.calculateDueDate(item)), "days")) &&
                                    item.DateOfClosure!=null);
        return result;
    },
    getWithExt(reqs){
      let result = reqs.filter(item=>item.ExtensionDays!=null &&
                                    item.ExtensionDays!=0);
      return result;
    },
     calculateDueDate(request){
      let requestType = this.getTypes( request.request_type );
      let cal = 0;
      cal =+ requestType?.time_frame_to_deliver || 0;
      cal =+ request.HoldDays;
      cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
      cal =+ request.ExtensionDays;
      let DueDate= moment(request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
      if(!requestType.editable_due_date && !request.DateDue)  
        return this.checkHolidayWeekend(DueDate);
      else
        return request.DateDue;
    },
    checkHolidayWeekend(date){
      let currentDay = moment(date).day();
      let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
      let totalHoliday = 0;
      if( holidays.length )
        totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
      if( currentDay === 6 || currentDay === 7 || totalHoliday ){
        if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
        if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
        this.checkHolidayWeekend(date);
      }
      return date;
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>