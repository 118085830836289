<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr>
                    <th></th>
                    <th class="text-left my-border customlightgray">
                        {{$t("Number of requests")}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.nofr"
                >
                    <td class="my-border customoffwhite">{{ item.name }}</td>
                    <td class="my-border customoffwhite">
                        <span v-if="item.nofr=='norn'">{{ getNORN() }}</span>
                        <span v-if="item.nofr=='noro'">{{ getNORO() }}</span>
                        <span v-if="item.nofr=='nort'">{{ getNORT() }}</span>
                        <span v-if="item.nofr=='norcl'">{{ getNORCl() }}</span>
                        <span v-if="item.nofr=='norca'">{{ getNORCa() }}</span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import moment from 'moment' 
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {

        List:[
                {name:this.$t('Number of requests'),nofr:'norn'},
                {name:this.$t('Outstanding from the previous reporting period'),nofr:'noro'},
                {name:this.$t('Total'),nofr:'nort'},
                {name:this.$t('Closed during the reporting period'),nofr:'norcl'},
                {name:this.$t('Carried over to next reporting period'),nofr:'norca'},
            ],
            
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {

    getNORN(){
        let result= this.requests.filter(item =>
        new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
        new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime());
        return result.length;

    },
    getNORO(){
        let result= this.requests.filter(item =>
        new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() < new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
        item.DateOfClosure==null);
        return result.length;
    },
    getNORT(){
        return this.getNORN()+this.getNORO();
    },
    getNORCl(){
        let result= this.requests.filter(item =>
        new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
        new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime());
        return result.length;
    },
    getNORCa(){
        let result= this.requests.filter(item =>    
        new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() > new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() ||
        item.DateOfClosure==null);
        return result.length;
    }, 
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>