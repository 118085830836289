<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr>
                    <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                        {{item.text}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.act"
                >
                    <td class="my-border customoffwhite">{{ item.pers }}</td>
                    <td class="my-border customoffwhite">{{item.act}}</td>
                    <td class="my-border customoffwhite">{{item.crea}}</td>
                    <td class="my-border customoffwhite">{{item.term}}</td>
                    <td class="my-border customoffwhite">{{item.mod}}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
            {text:this.$t('Personal Information Banks'),value:'pers'},
            {text:this.$t('Active'),value:'act'},
            {text:this.$t('Created'),value:'crea'},
            {text:this.$t('Terminated'),value:'term'},
            {text:this.$t('Modified'),value:'mod'},
        ],
        List:[
            {pers:'',act:'0',crea:'0',term:'0',mod:'0'},
        ],
            
    }
  },
  mounted () {
  },
  async created(){
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>