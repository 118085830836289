<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr>
                    <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                        {{item.text}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.pare"
                >
                    <td class="my-border customoffwhite">{{ item.pare }}</td>
                    <td class="my-border customoffwhite">{{ item.parm }}</td>
                    <td class="my-border customoffwhite">{{item.sub}}</td>
                    <td class="my-border customoffwhite">{{item.total}}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
                { text: this.$t('Paragraph 8(2)(e)'), value: 'pare' },
                { text: this.$t('Paragraph 8(2)(m)'), value: 'parm' },
                { text: this.$t('Subsection 8(5)'), value: 'sub' },
                { text: this.$t('Total'), value: 'total' },
            ],
        List:[
                {pare:'0',parm:'0',sub:'0',total:'0'}
            ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>