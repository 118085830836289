<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <tbody>
                <tr>
                    <td class="my-border customoffwhite">{{$t("Number of PIAs completed")}}</td>
                    <td class="my-border customoffwhite">0</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
    }
  },
  mounted () {
  },
  async created(){
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>