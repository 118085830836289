<template>
    <div>
      <v-row style="margin-bottom:22px;">
        <v-col cols="5">
          <v-img
            height="auto"
            width="auto"
            style="display:block;"
            :src="require('@/assets/gov-of-canada.jpg')"
          ></v-img>
        </v-col>
      </v-row>
        <center style="margin-top:20px; margin-bottom:20px;"><h4 class="text--secondary headline">{{$t("Statistical Report on the Access to Information Act w off")}}</h4></center>
        <h5 class="subtitle-1" style="margin-top:20px;"><span class="text--secondary">{{$t("Name of institution")}}:</span>  <b class="text--primary"> SoftSim Co.</b></h5>
        <h5 class="subtitle-1" style="margin-top:20px;">
          <span class="text--secondary">{{$t("Reporting period")}}: </span> 
          <span class="my-border text--primary" style="margin:4px;padding:8px">{{daterange[0]}}</span>
          <span class="text--secondary"> {{$t("to")}} </span>  
          <span class="my-border text--primary" style="margin:4px;padding:8px">{{daterange[1]}}</span>
        </h5>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 1: {{$t("Requests Under the Access to Information Act w off")}}</span></div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">1.1 {{$t("Number of requests")}}</h5></div>
            <numberofrequests :daterange="daterange" :requests="requests"></numberofrequests>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">1.2 {{$t("Sources of requests")}}</h5></div>
            <sourcesofrequests :daterange="daterange" :requests="requests"></sourcesofrequests>
          </div>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 2: {{$t("Requests Closed During the Reporting Period")}}</span></div>	
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">2.1 {{$t("Disposition and completion time")}}</h5></div>
            <dispositionandcompletiontime :daterange="daterange" :requests="requests" :type="1"></dispositionandcompletiontime>
          </div>
        </div>	
    </div>
</template>

<script>
import numberofrequests from '@/components/reports/tbs/Number_of_requests_report'
import sourcesofrequests from '@/components/reports/tbs/Sources_of_requests_report'
import dispositionandcompletiontime from '@/components/reports/tbs/Disposition_and_completion_time_report'
import { mapState,mapActions} from "vuex";
export default {
   components:{
        numberofrequests,
        sourcesofrequests,
        dispositionandcompletiontime,
    },
  props: ['daterange'],
  data () {
    return {
    }
  },
  mounted () {
  },  
  async created(){
      await this.retriveRequests()
  },
  computed:{ 
    ...mapState("request", {
      requests: "requests"
    }),
  },
  methods:{

    loadReport(item){
        this.e1=item[0]?item[0].title:'0'
    },
    
    ...mapActions('request', ['retriveRequests']),
  },
  watch: {
  }
}
</script>
<style type="text/css" media="print">
@media print {
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact !important;  
    forced-color-adjust: exact !important;
    overflow: auto;
    height: auto; 
  }
}
@page {
    size: auto; 
    margin-top: 0;
    margin-bottom: 0;
    margin-left:5px;
    margin-right:5px;
}
</style>
