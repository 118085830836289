<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"
            :key="item.dispo"
            >
                <td class="my-border customoffwhite">{{ item.dispo }}</td>
                <td class="my-border customoffwhite">{{ item.cons }}</td>
                <td class="my-border customoffwhite">{{ item.asse }}</td>
                <td class="my-border customoffwhite">{{ item.legal }}</td>
                <td class="my-border customoffwhite">{{ item.other }}</td>
                <td class="my-border customoffwhite">{{ item.total }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
              
        Headers:[
            {text:this.$t('Disposition'),value:'dispo'},
            {text:this.$t('Consultation required'),value:'cons'},
            {text:this.$t('Assessment of fees'),value:'asse'},
            {text:this.$t('Legal advice sought'),value:'legal'},
            {text:this.$t('Other'),value:'other'},
            {text:this.$t('Total'),value:'total'},
        ],
        List:[
                {dispo:this.$t('All disclosed'),cons:'0',asse:'0',legal:'0',other:'0',total:'0'},
                {dispo:this.$t('Disclosed in part'),cons:'0',asse:'0',legal:'0',other:'0',total:'0'},
                {dispo:this.$t('All exempted'),cons:'0',asse:'0',legal:'0',other:'0',total:'0'},
                {dispo:this.$t('All excluded'),cons:'0',asse:'0',legal:'0',other:'0',total:'0'},
                {dispo:this.$t('Request abandoned'),cons:'0',asse:'0',legal:'0',other:'0',total:'0'},
                {dispo:this.$t('Neither confirmed nor denied'),cons:'0',asse:'0',legal:'0',other:'0',total:'0'},
                {dispo:this.$t('Total'),cons:'0',asse:'0',legal:'0',other:'0',total:'0'},
            ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>