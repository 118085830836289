<template>
    <div>
      <v-row style="margin-bottom:22px;">
        <v-col cols="5">
          <v-img
            height="auto"
            width="auto"
            style="display:block;"
            :src="require('@/assets/gov-of-canada.jpg')"
          ></v-img>
        </v-col>
      </v-row>
        <center style="margin-top:20px; margin-bottom:20px;"><h4 class="text--secondary headline">{{$t("Statistical Report on the Privacy Act")}}</h4></center>
        <h5 class="subtitle-1" style="margin-top:20px;"><span class="text--secondary">{{$t("Name of institution")}}:</span>  <b class="text--primary"> SoftSim Co.</b></h5>
        <h5 class="subtitle-1" style="margin-top:20px;">
          <span class="text--secondary">{{$t("Reporting period")}}: </span> 
          <span class="my-border text--primary" style="margin:4px;padding:8px">{{daterange[0]}}</span>
          <span class="text--secondary"> {{$t("to")}} </span>  
          <span class="my-border text--primary" style="margin:4px;padding:8px">{{daterange[1]}}</span>
        </h5>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 1: {{$t("Requests Under the Privacy Act")}}</span></div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">1.1 {{$t("Number of requests")}}</h5></div>
            <numberofrequests :daterange="daterange" :requests="requests"></numberofrequests>
          </div>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 2: {{$t("Requests Closed During the Reporting Period")}}</span></div>	
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">2.1 {{$t("Disposition and completion time")}}</h5></div>
            <dispositionandcompletiontime :daterange="daterange" :requests="requests" :type="4"></dispositionandcompletiontime>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">2.2 {{$t("Exemptions")}}</h5></div>
            <exemptions :daterange="daterange" :requests="requests" :type="4"></exemptions>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.3 {{$t("Exclusions")}}</h5></div>
            <exclusions :daterange="daterange" :requests="requests" :type="4"></exclusions>		
          </div>	
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.4 {{$t("Format of information released")}}</h5></div>
            <formatofinformationreleased :daterange="daterange" :requests="requests"></formatofinformationreleased>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.5 {{$t("Complexity")}}</h5></div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.5.1 {{$t("Relevant pages processed and disclosed")}}</h5></div>
              <relevantpagesprocessed :daterange="daterange" :requests="requests"></relevantpagesprocessed>
            </div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.5.2 {{$t("Relevant pages processed and disclosed by size of requests")}}</h5></div>
              <relevantpagesprocessedbysize :daterange="daterange" :requests="requests"></relevantpagesprocessedbysize>
            </div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.5.3 {{$t("Other complexities")}}</h5></div>
              <othercomplexities :daterange="daterange" :requests="requests"></othercomplexities>
            </div>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.6 {{$t("Closed requests")}}</h5></div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.6.1 {{$t("Number of requests closed within legislated timelines")}}</h5></div>
              <requestsclosedwithinlegislated :daterange="daterange" :requests="requests"></requestsclosedwithinlegislated>
            </div>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.7 {{$t("Deemed refusals")}}</h5></div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.7.1 {{$t("Reasons for not meeting legislated timelines")}}</h5></div>
              <reasonsnotmeetingstatutory :daterange="daterange" :requests="requests"></reasonsnotmeetingstatutory>
            </div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.7.2 {{$t("Requests closed beyond legislated timelines (including any extension taken)")}}</h5></div>
              <requestsclosedbeyondlegislated :daterange="daterange" :requests="requests"></requestsclosedbeyondlegislated>
            </div>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.8 {{$t("Requests for translation")}}</h5></div>
            <requestsfortranslation :daterange="daterange" :requests="requests"></requestsfortranslation>	
          </div>	
        </div>	
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 3: {{$t("Disclosures under subsection 8(2) and 8(5)")}}</span></div>	
          <disclosuresundersubsection :daterange="daterange" :requests="requests"></disclosuresundersubsection>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 4: {{$t("Requests for correction of personal information and notations")}}</span></div>	
          <requestsforcorrectionofpersonal :daterange="daterange" :requests="requests"></requestsforcorrectionofpersonal>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 5: {{$t("Extensions")}}</span></div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">5.1 {{$t("Reasons for extensions and disposition of requests")}}</h5></div>
            <reasonsforextensions :daterange="daterange" :requests="requests" :type="4"></reasonsforextensions>
          </div>		
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">5.2 {{$t("Length of extensions")}}</h5></div>
            <lengthofextensions :daterange="daterange" :requests="requests" :type="4"></lengthofextensions>
          </div>					
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 6: {{$t("Consultations Received From Other Institutions and Organizations")}}</span></div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">6.1 {{$t("Consultations received from other Government of Canada institutions and organizations")}}</h5></div>
            <consultationsreceivedfromother :daterange="daterange" :requests="requests"></consultationsreceivedfromother>
          </div>		
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">6.2 {{$t("Recommendations and completion time for consultations received from other Government of Canada institutions")}}</h5></div>
            <recommendationsfromotherinstitutions :daterange="daterange" :requests="requests"></recommendationsfromotherinstitutions>
          </div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">6.3 {{$t("Recommendations and completion time for consultations received from other organizations")}}</h5></div>
            <recommendationsfromotherorganizations :daterange="daterange" :requests="requests"></recommendationsfromotherorganizations>
          </div>					
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 7: {{$t("Completion Time of Consultations on Cabinet Confidences")}}</span></div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">7.1 {{$t("Requests with Legal Services")}}</h5></div>
            <requestswithlegalservices :daterange="daterange" :requests="requests"></requestswithlegalservices>
          </div>		
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">7.2 {{$t("Requests with Privy Council Office")}}</h5></div>
            <requestswithprivycouncil :daterange="daterange" :requests="requests"></requestswithprivycouncil>
          </div>		
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 8: {{$t("Complaints and investigations")}}</span></div>	
          <complaintsandinvestigations :daterange="daterange" :requests="requests" :type="4"  ></complaintsandinvestigations>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 9: {{$t("Privacy Impact Assessments (PIAs) and Personal Information Banks (PIBs)")}}</span></div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">9.1 {{$t("Privacy Impact Assessments (PIAs)")}}</h5></div>
            <privacyimpactassessments :daterange="daterange" :requests="requests"></privacyimpactassessments> 
          </div>		
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">9.2 {{$t("Personal Information Banks (PIBs)")}}</h5></div>
            <personalinformationbanks :daterange="daterange" :requests="requests"></personalinformationbanks>
          </div>		
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 10: {{$t("Material Privacy Breaches")}}</span></div>	
          <materialprivacybreaches :daterange="daterange" :requests="requests"></materialprivacybreaches>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 11: {{$t("Resources Related to the Access to the Privacy Act")}}</span></div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">11.1 {{$t("Costs")}}</h5></div>
            <costs :daterange="daterange" :requests="requests"></costs> 
          </div>		
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">11.2 {{$t("Human Resources")}}</h5></div>
            <humanresources :daterange="daterange" :requests="requests"></humanresources>
          </div>		
        </div>
    </div>
</template>

<script>
import numberofrequests from '@/components/reports/tbs/Number_of_requests_report'
import dispositionandcompletiontime from '@/components/reports/tbs/Disposition_and_completion_time_report'
import exemptions from '@/components/reports/tbs/Exemptions_report'
import exclusions from '@/components/reports/tbs/Exclusions_report'
import formatofinformationreleased from '@/components/reports/tbs/Format_of_information_released_report'
import relevantpagesprocessed from '@/components/reports/tbs/Relevant_pages_processed_report'
import relevantpagesprocessedbysize from '@/components/reports/tbs/Relevant_pages_processed_by_size_report'
import othercomplexities from '@/components/reports/tbs/Other_complexities_report'
import requestsclosedwithinlegislated from '@/components/reports/tbs/Requests_closed_within_legislated_report'
import reasonsnotmeetingstatutory from '@/components/reports/tbs/Reasons_not_meeting_statutory_report'
import requestsclosedbeyondlegislated from '@/components/reports/tbs/Requests_closed_beyond_legislated_report'
import requestsfortranslation from '@/components/reports/tbs/Requests_for_translation_report'
import disclosuresundersubsection from '@/components/reports/tbs/Disclosures_under_subsection_report'
import requestsforcorrectionofpersonal from '@/components/reports/tbs/Requests_for_correction_of_personal_information_report'
import reasonsforextensions from '@/components/reports/tbs/Reasons_for_extensions_report'
import lengthofextensions from '@/components/reports/tbs/Length_of_extensions_report'
import consultationsreceivedfromother from '@/components/reports/tbs/Consultations_received_from_other_report' 
import recommendationsfromotherinstitutions from '@/components/reports/tbs/Recommendations_from_other_institutions_report'
import recommendationsfromotherorganizations from '@/components/reports/tbs/Recommendations_from_other_organizations_report'
import requestswithlegalservices from '@/components/reports/tbs/Requests_with_legal_services_report'
import requestswithprivycouncil from '@/components/reports/tbs/Requests_with_privy_council_report'
import complaintsandinvestigations from '@/components/reports/tbs/Complaints_and_investigations_report'
import privacyimpactassessments from '@/components/reports/tbs/Privacy_impact_assessments_report'
import personalinformationbanks from '@/components/reports/tbs/Personal_information_banks_report'
import materialprivacybreaches from '@/components/reports/tbs/Material_privacy_breaches_report'
import costs from '@/components/reports/tbs/Costs_report'
import humanresources from '@/components/reports/tbs/Human_resources_report'
import { mapState,mapActions} from "vuex";
export default {
   components:{
        numberofrequests,
        dispositionandcompletiontime,
        exemptions,
        exclusions,
        formatofinformationreleased,
        relevantpagesprocessed,
        relevantpagesprocessedbysize,
        othercomplexities,
        requestsclosedwithinlegislated,
        reasonsnotmeetingstatutory,
        requestsclosedbeyondlegislated,
        requestsfortranslation,
        disclosuresundersubsection,
        requestsforcorrectionofpersonal,
        reasonsforextensions,
        lengthofextensions,
        consultationsreceivedfromother,
        recommendationsfromotherinstitutions,
        recommendationsfromotherorganizations,
        requestswithlegalservices,
        requestswithprivycouncil,
        complaintsandinvestigations,
        privacyimpactassessments,
        personalinformationbanks,
        materialprivacybreaches,
        costs,
        humanresources,
    },
  props: ['daterange'],
  data () {
    return {
    }
  },
  mounted () {
  },  
  async created(){
      await this.retriveRequests()
  },
  computed:{ 
    ...mapState("request", {
      requests: "requests"
    }),
  },
  methods:{

    loadReport(item){
        this.e1=item[0]?item[0].title:'0'
    },
    
    ...mapActions('request', ['retriveRequests']),
  },
  watch: {
  }
}
</script>
<style type="text/css" media="print">
@media print {
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact !important;  
    forced-color-adjust: exact !important;
    overflow: auto;
    height: auto; 
  }
}
@page {
    size: auto; 
    margin-top: 0;
    margin-bottom: 0;
    margin-left:5px;
    margin-right:5px;
}
</style>
