<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr>
                    <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                        {{item.text}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.sofr"
                >
                    <td class="my-border customoffwhite">{{ item.name }}</td>
                    <td class="my-border customoffwhite">
                        <span v-if="item.sofr=='sort'">{{ getSORT() }}</span>
                        <span v-else>{{ getSOR(item.sofr) }}</span>
                    </td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import moment from 'moment' 
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
                { text: this.$t('Source'), value: 'name' },
                { text: this.$t('Number of requests'), value: 'sofr' },
            ],
        List:[
                {name:this.$t('Media'),sofr:'Media'},
                {name:this.$t('Academia'),sofr:'Academia'},
                {name:this.$t('Business (Private Sector)'),sofr:'Business'},
                {name:this.$t('Organization'),sofr:'Organization'},
                {name:this.$t('Public'),sofr:'Public'},
                {name:this.$t('Decline to Identify'),sofr:'Decline to Identify'},
                {name:this.$t('Total'),sofr:'sort'},
            ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{ 
  },
  methods: {

    getSORT(){
        return this.getSOR('Media')+this.getSOR('Academia')+this.getSOR('Business')+this.getSOR('Organization')+this.getSOR('Public')+this.getSOR('Decline to Identify');
    },


    getSOR(src){
        let result;
        if(src!='Public')
        result= this.requests.filter(item =>    
        new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
        new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() &&
        item.address.Source==src
        );
        else 
        result= this.requests.filter(item =>    
        new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
        new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() &&
        (item.address.Source=='Member of the Public' && item.address.Source!='Media' && item.address.Source!='Academia' && item.address.Source!='Business' && item.address.Source!='Organization' && item.address.Source!='Decline to Identify')
        );
        return result.length;
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>