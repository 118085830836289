<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th></th>
                <th class="text-left my-border customlightgray">
                    {{$t("Requests closed within legislated timelines")}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="my-border customoffwhite">{{$t("Number of requests closed within legislated timelines")}}</td>
                <td class="my-border customoffwhite">{{getNOR()}}</td>
            </tr>
            <tr>
                <td class="my-border customoffwhite">{{$t("Percentage of requests closed within legislated timelines (%)")}}</td>
                <td class="my-border customoffwhite">{{getNOR()/100}}%</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import { mapState , mapGetters } from "vuex";
import moment from "moment"
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
    }
  },
  mounted () {
      
  },
  async created(){ 
  },
  computed:{  
      ...mapState('configuration',{
        'configurations':'configurations'
      }),
      ...mapGetters({
      getTypes:"getTypes"
    }),
      
  },
  methods: {
    getNOR(){
        let result;
        let r1 = this.requests.filter(item=>new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")) > moment(this.calculateDueDate(item)) &&
                                    new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() &&
                                    item.DateOfClosure!=null);
        let r2 = this.requests.filter(item=>new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() &&
                                    item.DateOfClosure!=null)
        result = r2.length-r1.length
        return result;
    },
     calculateDueDate(request){
      let requestType = this.getTypes( request.request_type );
      let cal = 0;
      cal =+ requestType?.time_frame_to_deliver || 0;
      cal =+ request.HoldDays;
      cal =+ request.HoldDateStart ? moment().diff( moment(request.HoldDateStart ), "days") : 0;
      cal =+ request.ExtensionDays;
      let DueDate= moment(request.DateInfoComplete, "YYYY-MM-DD").add(cal,'days');
      if(!requestType.editable_due_date && !request.DateDue)  
        return this.checkHolidayWeekend(DueDate);
      else
        return request.DateDue;
    },
    checkHolidayWeekend(date){
      let currentDay = moment(date).day();
      let holidays = this.configurations.filter( filter => filter.LookUpName === 'Holiday' );
      let totalHoliday = 0;
      if( holidays.length )
        totalHoliday= holidays.filter( h => moment( h.LookUpKey, "DD-MM-YYYY") === moment( date, "DD-MM-YYYY" ) ).length;
      if( currentDay === 6 || currentDay === 7 || totalHoliday ){
        if(currentDay === 6 || currentDay === 7)  date = moment(date, "DD-MM-YYYY").add(8-currentDay, 'days');
        if(totalHoliday) date = moment(date, "DD-MM-YYYY").add(1, 'days');
        this.checkHolidayWeekend(date);
      }
      return date;
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>