<template>
<v-card class="pa-0 ma-0 elevation-0 white" style="max-height: 100%">
        <v-row class="pa-0 ma-0">
            <v-col cols="12" md="4" class="pa-0 ma-0 ">
                <v-row class="pa-0 ma-0">
                    <v-col cols="12" md="11" class="pa-0 ma-0">
                        <v-card-text class="pa-0 ma-0">
                            <v-treeview
                                v-model="tree"
                                :items="parts"
                                item-key="id"
                                activatable
                                :active.sync="active"
                                open-on-click
                                return-object
                                expand-icon="mdi-chevron-down"
                                @update:active="loadReport"
                            >
                                <template v-slot:prepend="{ item, open }" >
                                    <v-icon v-if="!item.file">
                                        {{ open ? 'mdi-clipboard-file-outline' : 'mdi-clipboard-file' }}
                                    </v-icon>
                                    <v-icon v-else>
                                    {{item.file}}
                                    </v-icon>
                                </template>
                            </v-treeview>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center pa-0 ma-0"><v-divider class="d-none d-md-block pa-0 ma-auto" vertical></v-divider><v-divider class="d-block d-md-none pa-0 ma-auto"></v-divider></v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="8" class="pa-0 ma-0">
                <v-card-text class="pa-0 ma-0">
                    <v-stepper v-model="e1" class="elevation-0">
                        <v-stepper-items class="white">
                            <v-stepper-content key="Number-of-Requests-content" step="Number of Requests">
                                <numberofrequests :daterange="daterange" :requests="requests"></numberofrequests>
                            </v-stepper-content>
                            <v-stepper-content key="Sources-of-Requests-content" step="Sources of Requests">
                                <sourcesofrequests :daterange="daterange" :requests="requests"></sourcesofrequests>
                            </v-stepper-content>
                            <v-stepper-content key="Informal-Requests-content" step="Informal Requests">
                                <informalrequests :daterange="daterange" :requests="requests"></informalrequests>
                            </v-stepper-content>
                            <v-stepper-content key="Dispostion-and-Completion-Time-content" step="Dispostion and Completion Time">
                                <dispositionandcompletiontime :daterange="daterange" :requests="requests"></dispositionandcompletiontime>
                            </v-stepper-content>
                            <v-stepper-content key="Exemptions-content" step="Exemptions">
                                <exemptions :daterange="daterange" :requests="requests"></exemptions>
                            </v-stepper-content>
                            <v-stepper-content key="Exclusions-content" step="Exclusions">
                                <exclusions :daterange="daterange" :requests="requests"></exclusions>
                            </v-stepper-content>
                            <v-stepper-content key="Format-of-Information-Released-content" step="Format of Information Released">
                                <formatofinformationreleased :daterange="daterange" :requests="requests"></formatofinformationreleased>
                            </v-stepper-content>
                            <v-stepper-content key="Relevant-pages-processed-and-disclosed-by-size-of-requests-content" step="Relevant pages processed and disclosed by size of requests">
                                <relevantpagesprocessedbysize :daterange="daterange" :requests="requests"></relevantpagesprocessedbysize>
                            </v-stepper-content>
                            <v-stepper-content key="Reasons-for-not-meeting-statutory-deadline-content" step="Reasons for not meeting statutory deadline">
                                <reasonsnotmeetingstatutory :daterange="daterange" :requests="requests"></reasonsnotmeetingstatutory>
                            </v-stepper-content>
                            <v-stepper-content key="Requests-for-translation-content" step="Requests for translation">
                                <requestsfortranslation :daterange="daterange" :requests="requests"></requestsfortranslation>
                            </v-stepper-content>
                            <v-stepper-content key="Disclosures-Under-Subsection-8(2)-and-8(5)-content" step="Disclosures Under Subsection 8(2) and 8(5)">
                               <disclosuresundersubsection :daterange="daterange" :requests="requests"></disclosuresundersubsection>
                            </v-stepper-content>
                            <v-stepper-content key="Request-for-Correction-of-Personal-Information-and-Notations-content" step="Request for Correction of Personal Information and Notations">
                                <requestsforcorrectionofpersonal :daterange="daterange" :requests="requests"></requestsforcorrectionofpersonal>
                            </v-stepper-content>
                            <v-stepper-content key="Reasons-for-extensions-and-disposition-of-requests-content" step="Reasons for extensions and disposition of requests">
                                <reasonsforextensions :daterange="daterange" :requests="requests"></reasonsforextensions>
                            </v-stepper-content>
                            <v-stepper-content key="Length-of-extensions-content" step="Length of extensions">
                                <lengthofextensions :daterange="daterange" :requests="requests"></lengthofextensions>
                            </v-stepper-content>
                            <v-stepper-content key="Consultations-received-from-other-government-institutions-and-organizations-content" step="Consultations received from other government institutions and organizations">
                                <consultationsreceivedfromother :daterange="daterange" :requests="requests"></consultationsreceivedfromother>
                            </v-stepper-content>
                            <v-stepper-content key="Recommendations-and-completion-time-for-consultations-received-from other-government-institutions-content" step="Recommendations and completion time for consultations received from other government institutions">
                                <recommendationsfromotherinstitutions :daterange="daterange" :requests="requests"></recommendationsfromotherinstitutions>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-col>
        </v-row>
  </v-card>
</template>

<script>
import numberofrequests from '@/components/reports/tbs/Number_of_requests_report'
import sourcesofrequests from '@/components/reports/tbs/Sources_of_requests_report'
import informalrequests from '@/components/reports/tbs/Informal_requests_report'
import dispositionandcompletiontime from '@/components/reports/tbs/Disposition_and_completion_time_report'
import exemptions from '@/components/reports/tbs/Exemptions_report'
import exclusions from '@/components/reports/tbs/Exclusions_report'
import formatofinformationreleased from '@/components/reports/tbs/Format_of_information_released_report'
import relevantpagesprocessedbysize from '@/components/reports/tbs/Relevant_pages_processed_by_size_report'
import reasonsnotmeetingstatutory from '@/components/reports/tbs/Reasons_not_meeting_statutory_report'
import requestsfortranslation from '@/components/reports/tbs/Requests_for_translation_report'
import disclosuresundersubsection from '@/components/reports/tbs/Disclosures_under_subsection_report'
import requestsforcorrectionofpersonal from '@/components/reports/tbs/Requests_for_correction_of_personal_information_report'
import reasonsforextensions from '@/components/reports/tbs/Reasons_for_extensions_report'
import lengthofextensions from '@/components/reports/tbs/Length_of_extensions_report'
import consultationsreceivedfromother from '@/components/reports/tbs/Consultations_received_from_other_report' 
import recommendationsfromotherinstitutions from '@/components/reports/tbs/Recommendations_from_other_institutions_report'
import { mapState,mapActions} from "vuex";
export default {
   components:{
        numberofrequests,
        sourcesofrequests,
        informalrequests,
        dispositionandcompletiontime,
        exemptions,
        exclusions,
        formatofinformationreleased,
        relevantpagesprocessedbysize,
        reasonsnotmeetingstatutory,
        requestsfortranslation,
        disclosuresundersubsection,
        requestsforcorrectionofpersonal,
        reasonsforextensions,
        lengthofextensions,
        consultationsreceivedfromother,
        recommendationsfromotherinstitutions,
    },
  props: ['daterange'],
  data () {
    return {
        e1:'0',
        tree: [],
        active: [],
        parts : [
            { 
                id: 1, 
                name: "1. "+this.$t('Requests Under the Access to Information Act'),
                title: "Requests under the Access to Information Act" ,
                children: [
                    {
                        id: 11,
                        name: "1-1 "+this.$t('Number of requests'),
                        title: "Number of Requests",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 12,
                        name: "1-2 "+this.$t('Sources of requests'),
                        title: "Sources of Requests",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 13,
                        name: "1-3 "+this.$t('Informal requests'),
                        title: "Informal Requests",
                        file: "mdi-file-eye-outline"
                    },
                ]
            },
            { 
                id: 2, 
                name: "2. "+this.$t('Requests Closed During the Reporting Period'),
                title: "Requests closed during the reporting period",
                children: [
                    {
                        id: 21,
                        name: "2-1 "+this.$t('Disposition and completion time'),
                        title: "Dispostion and Completion Time",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 22,
                        name: "2-2 "+this.$t('Exemptions'),
                        title: "Exemptions",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 23,
                        name: "2-3 "+this.$t('Exclusions'),
                        title: "Exclusions",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 24,
                        name: "2-4 "+this.$t('Format of information released'),
                        title: "Format of Information Released",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 25,
                        name: "2-5 "+this.$t('Complexity'),
                        title: "Complexity",
                        children: [
                            {
                                id: 251,
                                name: "2-5-1 "+this.$t('Relevant pages processed and disclosed by size of requests'),
                                title: "Relevant pages processed and disclosed by size of requests",
                                file: "mdi-file-eye-outline"
                            },
                        ]
                    }, 
                    {
                        id: 26,
                        name: "2-6 "+this.$t('Deemed refusals'),
                        title: "Deemed Refusals",
                        children: [
                            {
                                id: 261,
                                name: "2-6-1 "+this.$t('Reasons for not meeting statutory deadline'),
                                title: "Reasons for not meeting statutory deadline",
                                file: "mdi-file-eye-outline"
                            },
                        ]
                    },
                    {
                        id: 27,
                        name: "2-7 "+this.$t('Requests for translation'),
                        title: "Requests for translation",
                        file: "mdi-file-eye-outline"
                    },
                ]},
                     {
                        id: 3,
                        name: "3. "+this.$t('Disclosures under subsection 8(2) and 8(5)'),
                        title: "Disclosures Under Subsection 8(2) and 8(5)",
                        file: "mdi-file-eye-outline"
                    },
                     {
                        id: 4,
                        name: "4. "+this.$t('Requests for correction of personal information and notations'),
                        title: "Request for Correction of Personal Information and Notations",
                        file: "mdi-file-eye-outline"
                    },
                    { 
                        id: 5, 
                        name: "5. "+this.$t('Extensions'),
                        title: "Extensions" ,
                        children: [ 
                            {
                                id: 51,
                                name: "5-1 "+this.$t('Reasons for extensions and disposition of requests'),
                                title: "Reasons for extensions and disposition of requests",
                                file: "mdi-file-eye-outline"
                            },
                            {
                                id: 52,
                                name: "5-2 "+this.$t('Length of extensions'),
                                title: "Length of extensions",
                                file: "mdi-file-eye-outline"
                            },
                        ] 
                    },
                    {
                        id: 6,
                        name: "6. "+this.$t('Consultations Received From Other Institutions and Organizations'),
                        title: "Consultations received from other institutions and organizations",
                        children: [ 
                            {
                                id: 61,
                                name: "6-1 "+this.$t('Consultations received from other Government of Canada institutions and organizations'),
                                title: "Consultations received from other government institutions and organizations",
                                file: "mdi-file-eye-outline"
                            },
                            {
                                id: 62,
                                name: "6-2 "+this.$t('Recommendations and completion time for consultations received from other Government of Canada institutions'),
                                title: "Recommendations and completion time for consultations received from other government institutions",
                                file: "mdi-file-eye-outline"
                            },
                        ] 
                    },
            ],
    }
  },
  mounted () {
      this.active=[this.parts[0].children[0]]
      this.loadReport(this.parts[0].children)
  },
  async created(){
      await this.retriveRequests()
  },
  computed:{ 
    ...mapState("request", {
      requests: "requests"
    }),
  },
  methods:{

    loadReport(item){
        this.e1=item[0]?item[0].title:'0'
    },
    
    ...mapActions('request', ['retriveRequests']),
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>