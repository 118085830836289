<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr class="text-center">
                    <th rowspan="2" class="text-center my-border customlightgray">{{$t("Fee Type")}}</th>
                    <th colspan="2" class="text-center my-border customlightgray">{{$t("Fee Collected")}}</th>
                    <th colspan="2" class="text-center my-border customlightgray">{{$t("Fee Waived or Fee Refunded")}}</th>
                </tr>
                <tr>
                    <th class="text-left my-border customlightgray">  {{$t("Number of requests")}} </th>
                    <th class="text-left my-border customlightgray">  {{$t("Amount")}} </th>
                    <th class="text-left my-border customlightgray">  {{$t("Number of requests")}} </th>
                    <th class="text-left my-border customlightgray">  {{$t("Amount")}} </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.type"
                >
                    <td class="my-border customoffwhite">{{ item.type }}</td>
                    <td class="my-border customoffwhite">{{ getFees(item.val).length }}</td>
                    <td class="my-border customoffwhite">${{ getFees(item.val).reduce((a, b) => a + b, 0) }}</td>
                    <td class="my-border customoffwhite">{{ getFees(item.v).length }}</td>
                    <td class="my-border customoffwhite">${{ getFees(item.v).reduce((a, b) => a + b, 0) }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import moment from 'moment'
import { mapState } from "vuex";
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        List:[
        ],
    }
  },
  mounted () {
      this.List=[
            {type:this.$t('Application'),val:['appcol'],v:['appref']},
            {type:this.$t('Other fees'),val:this.getTypes().filter(item=>item.indexOf('col') !== -1 && item!='appcol' && item!='appref'),v:this.getTypes().filter(item=>(item.indexOf('ref') !== -1 || item=='FR') && item!='appcol' && item!='appref')},
            {type:this.$t('Total'),val:this.getTypes().filter(item=>item.indexOf('col') !== -1 ),v:this.getTypes().filter(item=>item.indexOf('ref') !== -1 || item=='FR')},]
  },
  async created(){ 
  },
  computed:{  
    ...mapState("configuration", {
      configurations: "configurations",
    }),
  },
  methods: {
    getFees(type){
        let result = this.requests.filter(item=> new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateInfoComplete).format("YYYY-MM-DD")).getTime())
                                    .map(i=>i.fees).filter(i=>i.length>0).flat().filter(item=>type.includes(item.fee_type)).map(item=>item.unitPrice*item.quantity)
       return result;
    },
    getTypes(){
        let items = this.getLookup('Fees');
        let result = items.map(i=>i.value)
        return result
    },
    getLookup(column, val='') {
      return this.configurations
        .filter((filter) => {
          if(val){
            return filter.LookUpName === column && filter.LookUpKey === val
          }else{
            return filter.LookUpName === column
          }
        })
        .map((item) => {
          let arr = [];
          arr["text"] =
            this.$vuetify.lang.current === "en"
              ? item.LookUpStringE
              : item.LookUpStringF;
          arr["value"] = item.LookUpKey;
          return arr;
        });
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>