<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr class="text-center">
                    <th rowspan="2" class="text-center my-border customlightgray">{{$t("Disposition of requests")}}</th>
                    <th :colspan="Headers.length" class="text-center my-border customlightgray">
                        {{$t("Completion Time")}}
                    </th>
                </tr>
                <tr>
                    <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                        {{item.text}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.name"
                >
                    <td class="my-border customoffwhite">{{ item.name }}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[0].range)}}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[1].range)}}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[2].range)}}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[3].range)}}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[4].range)}}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[5].range)}}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[6].range)}}</td>
                    <td class="my-border customoffwhite">{{getAll(item.val,ranges[7].range)}}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import moment from 'moment'
export default {
  components: {
  },
  props: ['daterange','requests','type'],
  data () {
    return {
        ranges:[{name:'range1',range:[1,15]},{name:'range16',range:[16,30]},{name:'range31',range:[31,60]},{name:'range61',range:[61,120]},{name:'range121',range:[121,180]},{name:'range181',range:[181,365]},{name:'range365',range:[366,99999999]},{name:'total',range:[1,99999999]}],
        Headers:[
                
                { text: this.$t('1 to 15 days'), value: 'range1' },
                { text: this.$t('16 to 30 days'), value: 'range16' },
                { text: this.$t('31 to 60 days'), value: 'range31' },
                { text: this.$t('61 to 120 days'), value: 'range61' },
                { text: this.$t('121 to 180 days'), value: 'range121' },
                { text: this.$t('181 to 365 days'), value: 'range181' },
                { text: this.$t('More than 365 days'), value: 'range365' },
                { text: this.$t('Total'), value: 'total' },
            ],
        List:[
                {name:this.$t('All disclosed'),val:'ALL',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Disclosed in part'),val:'PART',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('All exempted'),val:'EXEMPT',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('All excluded'),val:'EXCL',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('No records exist'),val:'NOREC',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Request transferred'),val:'XFER',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Request abandoned'),val:'ABANDON',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Neither confirmed nor denied'),val:'NCND',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Total'),val:'total',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
            ],
    }
  },
  mounted () {
      if(this.type==4)
            this.List=[
                {name:this.$t('All disclosed'),val:'ALL',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Disclosed in part'),val:'PART',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('All exempted'),val:'EXEMPT',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('All excluded'),val:'EXCL',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('No records exist'),val:'NOREC',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Request abandoned'),val:'ABANDON',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Neither confirmed nor denied'),val:'NCND',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
                {name:this.$t('Total'),val:'total',range1:'0',range16:'0',range31:'0',range61:'0',range121:'0',range181:'0',range365:'0',total:'0'},
            ];
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
    getAll(dispo,range){
        let result=[];
        if(dispo!='total')
            result= this.requests.filter(item =>    
                new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() &&
                item.RequestDisposition==dispo && 
                item.DateOfClosure!=null &&
                moment(item.DateOfClosure).diff(moment(item.DateInfoComplete),'days')>=range[0] &&
                range[1]>=moment(item.DateOfClosure).diff(moment(item.DateInfoComplete),'days')
            );
        else
            result= this.requests.filter(item =>    
                new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() &&
                (item.RequestDisposition=='ALL' || item.RequestDisposition=='PART' || item.RequestDisposition=='EXEMPT' || item.RequestDisposition=='EXCL' || item.RequestDisposition=='NOREC' || item.RequestDisposition=='XFER' || item.RequestDisposition=='ABANDON' || item.RequestDisposition=='NCND' )&& 
                item.DateOfClosure!=null &&
                moment(item.DateOfClosure).diff(moment(item.DateInfoComplete),'days')>=range[0] &&
                range[1]>=moment(item.DateOfClosure).diff(moment(item.DateInfoComplete),'days')
            );
        return result.length
    },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>