<template>
    <v-simple-table v-if="type==4" class="text-wrap white">
        <template v-slot:default>
            <thead>
                <tr class="text-center">
                    <th rowspan="2" class="text-center my-border customlightgray">{{$t("Number of requests where an extension was taken")}}</th>
                    <th colspan="4" class="text-center my-border customlightgray">{{$t("15(a)(i) Interference with operations")}}</th>
                    <th colspan="3" class="text-center my-border customlightgray">{{$t("15(a)(ii) Consultation")}}</th>
                    <th rowspan="2" class="text-center my-border customlightgray">{{$t("15(b) Translation or conversion")}}</th>
                </tr>
                <tr>
                    <th class="text-left my-border customlightgray" v-for="item in Headers" :key="item.value">  {{item.text}} </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.nofr"
                >
                    <td class="my-border customoffwhite">{{ item.nofr }}</td>
                    <td class="my-border customoffwhite">{{ item.fur }}</td>
                    <td class="my-border customoffwhite">{{ item.pagevol }}</td>
                    <td class="my-border customoffwhite">{{ item.reqvol }}</td>
                    <td class="my-border customoffwhite">{{ item.docs }}</td>
                    <td class="my-border customoffwhite">{{ item.cabi }}</td>
                    <td class="my-border customoffwhite">{{ item.ext }}</td>
                    <td class="my-border customoffwhite">{{ item.int }}</td>
                    <td class="my-border customoffwhite">{{ item.trans }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
    <v-simple-table v-else class="text-wrap white">
        <template v-slot:default>
        <thead>
            <tr class="text-center">
                <th rowspan="2" class="text-center my-border customlightgray">{{$t("Disposition of requests where an extension was taken")}}</th>
                <th rowspan="2" class="text-center my-border customlightgray">{{$t("9(1)(a) Interference with operations")}}</th>
                <th colspan="2" class="text-center my-border customlightgray">{{$t("9(1)(b) Consultation")}} </th>
                <th rowspan="2" class="text-center my-border customlightgray">{{$t("9(1)(c) Third party notice")}}</th>
            </tr>
            <tr>
                <th class="text-left my-border customlightgray">  Section 69 </th>
                <th class="text-left my-border customlightgray">  {{$t("Other")}} </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"
            :key="item.dispo"
            >
                <td class="my-border customoffwhite">{{ item.dispo }}</td>
                <td class="my-border customoffwhite">{{ item.interf }}</td>
                <td class="my-border customoffwhite">{{ item.sec69 }}</td>
                <td class="my-border customoffwhite">{{ item.other }}</td>
                <td class="my-border customoffwhite">{{ item.third }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests','type'],
  data () {
    return {
        
        Headers:[],
        List:[
            {dispo:this.$t('All disclosed'),interf:'0',sec69:'0',other:'0',third:'0'},
            {dispo:this.$t('Disclosed in part'),interf:'0',sec69:'0',other:'0',third:'0'},
            {dispo:this.$t('All exempted'),interf:'0',sec69:'0',other:'0',third:'0'},
            {dispo:this.$t('All excluded'),interf:'0',sec69:'0',other:'0',third:'0'},
            {dispo:this.$t('Request abandoned'),interf:'0',sec69:'0',other:'0',third:'0'},
            {dispo:this.$t('Neither confirmed nor denied'),interf:'0',sec69:'0',other:'0',third:'0'},
            {dispo:this.$t('Total'),interf:'0',sec69:'0',other:'0',third:'0'},
        ],


    }
  },
  mounted () {
       if(this.type==4){
           this.Headers=[
                { text: this.$t('Further review required to determine exemptions'), value: 'fur' },
                { text: this.$t('Large volume of pages'), value: 'pagevol' },    
                { text: this.$t('Large volume of requests'), value: 'reqvol' },    
                { text: this.$t('Documents are difficult to obtain'), value: 'docs' },    
                { text: this.$t('Cabinet Confidence (Section 70)'), value: 'cabi' },    
                { text: this.$t('External'), value: 'ext' },    
                { text: this.$t('Internal'), value: 'int' },    
            ];
            this.List=[
                {nofr:this.$t('Total'),fur:'0',pagevol:'0',reqvol:'0',docs:'0',cabi:'0',ext:'0',int:'0',trans:'0'}
            ];
       }
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.text-wrap{
    word-break: break-word;
}
</style>