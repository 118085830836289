<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"
            :key="item.cons"
            >
                <td class="my-border customoffwhite">{{ item.cons }}</td>
                <td class="my-border customoffwhite">{{ item.othergo }}</td>
                <td class="my-border customoffwhite">{{ item.nofp }}</td>
                <td class="my-border customoffwhite">{{ item.otherorg }}</td>
                <td class="my-border customoffwhite">{{ item.nofporg }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {

        Headers:[
            {text:this.$t('Consultations'),value:'cons'},
            {text:this.$t('Other Government of Canada institutions'),value:'othergo'},
            {text:this.$t('Number of pages to review'),value:'nofp'},
            {text:this.$t('Other organizations'),value:'otherorg'},
            {text:this.$t('Number of pages to review'),value:'nofporg'},
        ],
        List:[
            {cons :this.$t('Received during reporting period'),othergo:'0',nofp:'0',otherorg:'0',nofporg:'0'},
            {cons :this.$t('Outstanding from the previous reporting period'),othergo:'0',nofp:'0',otherorg:'0',nofporg:'0'},
            {cons :this.$t('Total'),othergo:'0',nofp:'0',otherorg:'0',nofporg:'0'},
            {cons :this.$t('Closed during the reporting period'),othergo:'0',nofp:'0',otherorg:'0',nofporg:'0'},
            {cons :this.$t('Carried over to next reporting period'),othergo:'0',nofp:'0',otherorg:'0',nofporg:'0'},
        ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>