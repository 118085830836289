<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody v-if="type==4">
            <tr
            v-for="item in List"
            :key="item.sec31"
            >     
                <td class="my-border customoffwhite">{{ item.sec31 }}</td>
                <td class="my-border customoffwhite">{{ item.sec33 }}</td>
                <td class="my-border customoffwhite">{{ item.sec35 }}</td>
                <td class="my-border customoffwhite">{{ item.court }}</td>
                <td class="my-border customoffwhite">{{ item.total }}</td>
            </tr>
        </tbody>
        <tbody v-else>
            <tr
            v-for="item in List"
            :key="item.sec32"
            >     
                <td class="my-border customoffwhite">{{ item.sec32 }}</td>
                <td class="my-border customoffwhite">{{ item.subsec30 }}</td>
                <td class="my-border customoffwhite">{{ item.sec35 }}</td>
                <td class="my-border customoffwhite">{{ item.sec371 }}</td>
                <td class="my-border customoffwhite">{{ item.sec372 }}</td>
                <td class="my-border customoffwhite">{{ item.sec373 }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests','type'],
  data () {
    return {
        
        Headers:[
            {text:this.$t('Section 32 Notice of intention to investigate'),value:'sec32'},
            {text:this.$t('Subsection 30(5) Ceased to investigate'),value:'subsec30'}, 
            {text:this.$t('Section 35 Formal representations'),value:'sec35'},
            {text:this.$t('Section 37 Reports of finding received'),value:'sec371'},
            {text:this.$t('Section 37 Reports of finding containing recommendations issued by the Information Commissioner'),value:'sec372'},
            {text:this.$t('Section 37 Reports of finding containing orders issued by the Information Commissioner'),value:'sec373'},
        ],
        List:[
                {sec32:'0',subsec30:'0',sec35:'0',sec371:'0',sec372:'0',sec373:'0'},
        ],
    }
  },
  mounted () {
       if(this.type==4){
           this.Headers=[
                { text: 'Section 31', value: 'sec31' },
                { text: 'Section 33', value: 'sec33' },    
                { text: 'Section 35', value: 'sec35' },    
                { text: this.$t('Court action'), value: 'court' },    
                { text: this.$t('Total'), value: 'total' },    
            ];
            this.List=[
                {sec31:'0',sec33:'0',sec35:'0',court:'0',total:'0'}
            ];
       }
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>