<template>
  <div class="ma-0 pa-0 my-border elevation-0">
      <div class="pa-0 ma-0 white" style="min-height:calc(100vh - 130px)">
        <v-row class="pa-0 ma-0">
            <v-col cols="12" class="pa-3 ma-0">
                <annualreportrequest @setType="setType" @setDateRange="setDateRange" @setShow="setShow"></annualreportrequest>
            </v-col>
            <v-expand-transition>
                <v-col v-show="show && type!=null && !view" cols="12" class="pa-3 ma-0">
                    <div class="text-right pa-0 ma-0">
                        <v-btn class="ma-0 mr-1 pa-3 elevation-0 custom-color-accent" @click="view=true">{{$t('View Full Report')}}</v-btn>
                    </div>
                    <accesstoinformationact v-if="type==0" :daterange="daterange"></accesstoinformationact>
                    <accesstoinformationactwoff v-if="type==1" :daterange="daterange"></accesstoinformationactwoff>
                    <ativalidation v-if="type==2" :daterange="daterange"></ativalidation>
                    <privacyvalidation v-if="type==3" :daterange="daterange"></privacyvalidation>
                    <privacyact v-if="type==4" :daterange="daterange"></privacyact>
                </v-col>
            </v-expand-transition>
             <v-expand-transition>
                <v-col v-show="view" cols="12" class="pa-3 ma-0">
                    <div class="text-right pa-0 ma-0">
                        <v-btn class="ma-1 pa-3 elevation-0 custom-color-accent" @click="view=false">{{$t("Exit")}}</v-btn>
                        <v-btn class="ma-1 pa-3 elevation-0 custom-color-accent" @click="print_report()">{{$t("print")}}</v-btn>    
                        <v-btn class="ma-1 pa-3 elevation-0 custom-color-accent" @click="exportTableToExcel('full_report')">{{$t("export")}}</v-btn>
                    </div>
                    <v-row class="pa-0 ma-0">
                        <v-col cols="12" sm="12" md="10" lg="7" class="ma-auto" id="full_report">
                            <accesstoinformationactprint v-if="type==0" :daterange="daterange" id="print0"></accesstoinformationactprint>
                            <accesstoinformationactwoffprint v-if="type==1" :daterange="daterange" id="print1"></accesstoinformationactwoffprint>
                            <ativalidationprint v-if="type==2" :daterange="daterange" id="print2"></ativalidationprint>
                            <privacyvalidationprint v-if="type==3" :daterange="daterange" id="print3"></privacyvalidationprint>
                            <privacyactprint v-if="type==4" :daterange="daterange" id="print4"></privacyactprint>
                        </v-col>
                    </v-row>
                </v-col>
            </v-expand-transition>
        </v-row>
      </div>
  </div>
</template>
<script>
import annualreportrequest from "../components/reports/tbs/annual_report_request";
import accesstoinformationact from "@/components/reports/tbs/Access_to_information_Act";
import accesstoinformationactprint from "@/components/reports/tbs/Access_to_information_Act_print";
import privacyactprint from "@/components/reports/tbs/Privacy_act_print";
import accesstoinformationactwoffprint from "@/components/reports/tbs/Access_to_information_Act_w_off_print";
import ativalidationprint from "@/components/reports/tbs/ATI_Validation_print";
import privacyvalidationprint from "@/components/reports/tbs/Privacy_Validation_print";
import accesstoinformationactwoff from "@/components/reports/tbs/Access_to_information_Act_w_off";
import ativalidation from "@/components/reports/tbs/ATI_Validation";
import privacyvalidation from "@/components/reports/tbs/Privacy_Validation";
import privacyact from "@/components/reports/tbs/Privacy_act";                                              
export default({
  components: {
      annualreportrequest,
      accesstoinformationact,
      accesstoinformationactprint,
      privacyactprint,
      accesstoinformationactwoffprint,
      ativalidationprint,
      privacyvalidationprint,
      accesstoinformationactwoff,
      ativalidation,
      privacyvalidation,
      privacyact,
  },
    data() {
        return {
            type:null,
            show:false,
            view:false,
            daterange:[],
            reports: [
            {
                id: 0,
                label: this.$t("Access-to-information-Act-Report"),
            },
            {
                id: 1,
                label: this.$t("Access-to-information-Act-w-off-Report"),
            },
            {
                id: 2,
                label: this.$t("ATI-Validation-Report"),
            },
            {
                id: 3,
                label: this.$t("Privacy-Validation-Report"),
            },
            {
                id: 4,
                label: this.$t("Privacy-act-Report"),
            },
            ],
        }
    },
    methods:{   
    exportTableToExcel(tableID){
        var downloadLink;
        var dataType = 'application/vnd.ms-excel';
        var tableSelect = document.getElementById(tableID);
        var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');
        var filename=this.$t('TBS')+'-'+this.reports[this.type].label+'-'+localStorage.getItem('language')+'-'+this.daterange[0]+'-to-'+this.daterange[1];
        // Specify file name
        filename = filename?filename+'.xls':'excel_data.xls';
        
        // Create download link element
        downloadLink = document.createElement("a");
        
        document.body.appendChild(downloadLink);
        if(navigator.msSaveOrOpenBlob){
            var blob = new Blob(['\ufeff', tableHTML], {
                type: dataType
            });
            navigator.msSaveOrOpenBlob( blob, filename);
        }else{
            // Create a link to the file
            downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
        
            // Setting the file name
            downloadLink.download = filename;
            
            //triggering the function
            downloadLink.click();
        }
    },
        print_report(){
            const prtHtml = document.getElementById('print'+this.type).innerHTML;
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
            stylesHtml += node.outerHTML;
            }
            const WinPrint = window.open('', '', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
            
            WinPrint.document.write(`<!DOCTYPE html>
            <html>
            <head>
            <meta charset="utf-8">
            <meta http-equiv="X-UA-Compatible" content="IE=edge,chrome=1">
            <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no, minimum-scale=1.0, maximum-scale=1.0">
            <link rel="icon" href="<%= BASE_URL %>favicon.png">
            <title>${this.$t('TBS')+'-'+this.reports[this.type].label+'-'+localStorage.getItem('language')+'-'+this.daterange[0]+'-to-'+this.daterange[1]}</title>
            <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900">
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css">
                ${stylesHtml}
            <style>
            .text--secondary {
                color:#020202 !important;
            }
            .v-application .my-border {
                border: 1px solid #7a7a7a52 !important; 
                color:#020202 !important;
            }
            .white{
                background-color:#FFFFFF !important; 
                color:#020202 !important;
            }
            .gray{
                background-color:#f0f0f0 !important; 
                color:#020202 !important;
            }
            .customlightgray{
                background-color:#f8f8f8 !important; 
                color:#020202 !important;
                border: 1px solid #7a7a7a52 !important; color:#020202 !important;
            }
            .customoffwhite{
                background-color:#fdfdfd !important; 
                color:#020202 !important;
                border: 1px solid #7a7a7a52 !important; color:#020202 !important;
            }
            </style>
            </head>
            <body>
                ${prtHtml}
            </body>
            </html>`);
            WinPrint.document.close();
            WinPrint.focus();
            WinPrint.print();
            WinPrint.close();
        },
        setDateRange(items){
            this.daterange=items;
        },
        setType(type){
            this.type=type;
        },
        setShow(item){
            this.show=item;
        }
    },
})
</script>
<style scoped>

</style>
