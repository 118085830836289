<template>
    <v-simple-table class="text-wrap white">">
        <template v-slot:default>
        <thead>
            <tr class="text-center">
                <th rowspan="2" class="text-center my-border customlightgray">Number of Days</th>
                <th colspan="2" class="text-center my-border customlightgray">
                    {{$t("Less than 100 pages processed")}}
                </th>
                <th colspan="2" class="text-center my-border customlightgray">
                    {{$t("101-500 pages processed")}}
                </th>
                <th colspan="2" class="text-center my-border customlightgray">
                    {{$t("501-1000 pages processed")}}
                </th>
                <th colspan="2" class="text-center my-border customlightgray">
                    {{$t("1001-5000 pages processed")}}
                </th>
                <th colspan="2" class="text-center my-border customlightgray">
                    {{$t("More than 5000 pages processed")}}
                </th>
            </tr>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"
            :key="item.nofd"
            >
                <td class="my-border customoffwhite">{{ item.nofd }}</td>
                <td class="my-border customoffwhite">{{ item.nofr }}</td>
                <td class="my-border customoffwhite">{{ item.pd }}</td>
                <td class="my-border customoffwhite">{{ item.nofr101 }}</td>
                <td class="my-border customoffwhite">{{ item.pd101 }}</td>
                <td class="my-border customoffwhite">{{ item.nofr501 }}</td>
                <td class="my-border customoffwhite">{{ item.pd501 }}</td>
                <td class="my-border customoffwhite">{{ item.nofr1001 }}</td>
                <td class="my-border customoffwhite">{{ item.pd1001 }}</td>
                <td class="my-border customoffwhite">{{ item.nofr5000 }}</td>
                <td class="my-border customoffwhite">{{ item.pd5000 }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
            {text:this.$t('Number of requests'),value:'nofr'},
            {text:this.$t('Pages disclosed'),value:'pd'},
            {text:this.$t('Number of requests'),value:'nofr101'},
            {text:this.$t('Pages disclosed'),value:'pd101'},
            {text:this.$t('Number of requests'),value:'nofr501'},
            {text:this.$t('Pages disclosed'),value:'pd501'},
            {text:this.$t('Number of requests'),value:'nofr1001'},
            {text:this.$t('Pages disclosed'),value:'pd1001'},
            {text:this.$t('Number of requests'),value:'nofr5000'},
            {text:this.$t('Pages disclosed'),value:'pd5000'},
        ],
        List:[
                {nofd:this.$t('1 to 15 days'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
                {nofd:this.$t('16 to 30 days'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
                {nofd:this.$t('31 to 60 days'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
                {nofd:this.$t('61 to 120 days'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
                {nofd:this.$t('121 to 180 days'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
                {nofd:this.$t('181 to 365 days'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
                {nofd:this.$t('More than 365 days'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
                {nofd:this.$t('Total'),nofr:'0',pd:'0',nofr101:'0',pd101:'0',nofr501:'0',pd501:'0',nofr1001:'0',pd1001:'0',nofr5000:'0',pd5000:'0'},
            ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
.text-wrap{
    word-break: break-word;
}
</style>