<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr>
                    <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                        {{item.text}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td class="my-border customoffwhite">{{ getPaper() }}</td>
                    <td class="my-border customoffwhite">{{ getElectronic() }}</td>
                    <td class="my-border customoffwhite">{{ getOther() }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
import moment from 'moment'
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
                { text: this.$t('Paper'), value: 'paper' },
                { text: this.$t('Electronic'), value: 'electronic' },
                { text: this.$t('Other'), value: 'other' },
            ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
      getPaper(){
        let result =  this.requests.filter(item=>(item.RequestCorrespondPref=='C' ||
                        item.RequestCorrespondPref=='H' ||
                        item.RequestCorrespondPref=='M') && 
                        new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                        new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() &&
                        item.DateOfClosure!=null);
        return result.length;
      },
      getElectronic(){
        let result =  this.requests.filter(item=>(item.RequestCorrespondPref=='F' || item.RequestCorrespondPref=='O' || item.RequestCorrespondPref=='E') && 
                        new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                        new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() &&
                        item.DateOfClosure!=null);
        return result.length;
      },
      getOther(){
        let result =  this.requests.filter(item=>(item.RequestCorrespondPref=='N') && 
                        new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                        new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() &&
                        item.DateOfClosure!=null);
        return result.length;
      },
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>