<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th :colspan="Headers.length" class="text-center my-border customlightgray">{{$t('Section 41 (complaints made after coming into force of Bill C-58)')}}</th>
            </tr>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"    
            :key="item.comp"
            >     
                <td class="my-border customoffwhite">{{ item.comp }}</td>
                <td class="my-border customoffwhite">{{ item.inst }}</td>
                <td class="my-border customoffwhite">{{ item.third }}</td>
                <td class="my-border customoffwhite">{{ item.priv }}</td>
                <td class="my-border customoffwhite">{{ item.total }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
            {text:this.$t('Complainant (1)'),value:'comp'},
            {text:this.$t('Institution (2)'),value:'inst'}, 
            {text:this.$t('Third Party  (3)'),value:'third'},
            {text:this.$t('Privacy Commissioner (4)'),value:'priv'},
            {text:this.$t('Total'),value:'total'},
        ],
        List:[
                {comp:'0',inst:'0',third:'0',priv:'0',total:'0'},
        ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>