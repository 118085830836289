<template>
<v-card class="pa-0 ma-0 elevation-0 white" style="max-height: 100%">
        <v-row class="pa-0 ma-0">
            <v-col cols="12" md="4" class="pa-0 ma-0 ">
                <v-row class="pa-0 ma-0">
                    <v-col cols="12" md="11" class="pa-0 ma-0">
                        <v-card-text class="pa-0 ma-0">
                            <v-treeview
                                v-model="tree"
                                :items="parts"
                                item-key="id"
                                activatable
                                :active.sync="active"
                                open-on-click
                                return-object
                                expand-icon="mdi-chevron-down"
                                @update:active="loadReport"
                            >
                                <template v-slot:prepend="{ item, open }" >
                                    <v-icon v-if="!item.file">
                                        {{ open ? 'mdi-clipboard-file-outline' : 'mdi-clipboard-file' }}
                                    </v-icon>
                                    <v-icon v-else>
                                    {{item.file}}
                                    </v-icon>
                                </template>
                            </v-treeview>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center pa-0 ma-0"><v-divider class="d-none d-md-block pa-0 ma-auto" vertical></v-divider><v-divider class="d-block d-md-none pa-0 ma-auto"></v-divider></v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="8" class="pa-0 ma-0">
                <v-card-text class="pa-0 ma-0">
                    <v-stepper v-model="e1" class="elevation-0">
                        <v-stepper-items class="white">
                            <v-stepper-content key="Number-of-Requests-content" step="Number of Requests">
                                <numberofrequests :daterange="daterange" :requests="requests"></numberofrequests>
                            </v-stepper-content>
                            <v-stepper-content key="Dispostion-and-Completion-Time-content" step="Dispostion and Completion Time">
                                <dispositionandcompletiontime :daterange="daterange" :requests="requests" :type="4"></dispositionandcompletiontime>
                            </v-stepper-content>
                            <v-stepper-content key="Exemptions-content" step="Exemptions">
                                <exemptions :daterange="daterange" :requests="requests" :type="4"></exemptions>
                            </v-stepper-content>
                            <v-stepper-content key="Exclusions-content" step="Exclusions">
                                <exclusions :daterange="daterange" :requests="requests" :type="4"></exclusions>
                            </v-stepper-content>
                            <v-stepper-content key="Format-of-Information-Released-content" step="Format of Information Released">
                                <formatofinformationreleased :daterange="daterange" :requests="requests"></formatofinformationreleased>
                            </v-stepper-content>
                            <v-stepper-content key="Relevant-pages-processed-and-disclosed-content" step="Relevant pages processed and disclosed">
                                <relevantpagesprocessed :daterange="daterange" :requests="requests"></relevantpagesprocessed>
                            </v-stepper-content>
                            <v-stepper-content key="Relevant-pages-processed-and-disclosed-by-size-of-requests-content" step="Relevant pages processed and disclosed by size of requests">
                                <relevantpagesprocessedbysize :daterange="daterange" :requests="requests"></relevantpagesprocessedbysize>
                            </v-stepper-content>
                            <v-stepper-content key="Other-Complexities-content" step="Other Complexities">
                                <othercomplexities :daterange="daterange" :requests="requests"></othercomplexities>
                            </v-stepper-content>   
                            <v-stepper-content key="Number-of-requests-closed-within-legislated-timelines-content" step="Number of requests closed within legislated timelines">
                                <requestsclosedwithinlegislated :daterange="daterange" :requests="requests"></requestsclosedwithinlegislated>
                            </v-stepper-content>   
                            <v-stepper-content key="Reasons-for-not-meeting-statutory-deadline-content" step="Reasons for not meeting statutory deadline">
                                <reasonsnotmeetingstatutory :daterange="daterange" :requests="requests"></reasonsnotmeetingstatutory>
                            </v-stepper-content>
                            <v-stepper-content key="Requests-closed-beyond-legislated-timelines-content" step="Requests closed beyond legislated timelines">
                                <requestsclosedbeyondlegislated :daterange="daterange" :requests="requests"></requestsclosedbeyondlegislated>
                            </v-stepper-content>
                            <v-stepper-content key="Requests-for-translation-content" step="Requests for translation">
                                <requestsfortranslation :daterange="daterange" :requests="requests"></requestsfortranslation>
                            </v-stepper-content>
                            <v-stepper-content key="Disclosures-Under-Subsection-8(2)-and-8(5)-content" step="Disclosures Under Subsection 8(2) and 8(5)">
                                <disclosuresundersubsection :daterange="daterange" :requests="requests"></disclosuresundersubsection>
                            </v-stepper-content>
                            <v-stepper-content key="Request-for-Correction-of-Personal-Information-and-Notations-content" step="Request for Correction of Personal Information and Notations">
                                <requestsforcorrectionofpersonal :daterange="daterange" :requests="requests"></requestsforcorrectionofpersonal>
                            </v-stepper-content>
                            <v-stepper-content key="Reasons-for-extensions-and-disposition-of-requests-content" step="Reasons for extensions and disposition of requests">
                                <reasonsforextensions :daterange="daterange" :requests="requests" :type="4"></reasonsforextensions>
                            </v-stepper-content>
                            <v-stepper-content key="Length-of-extensions-content" step="Length of extensions">
                                <lengthofextensions :daterange="daterange" :requests="requests" :type="4"></lengthofextensions>
                            </v-stepper-content>
                            <v-stepper-content key="Consultations-received-from-other-government-institutions-and-organizations-content" step="Consultations received from other government institutions and organizations">
                                <consultationsreceivedfromother :daterange="daterange" :requests="requests"></consultationsreceivedfromother>
                            </v-stepper-content>
                            <v-stepper-content key="Recommendations-and-completion-time-for-consultations-received-from other-government-institutions-content" step="Recommendations and completion time for consultations received from other government institutions">
                                <recommendationsfromotherinstitutions :daterange="daterange" :requests="requests"></recommendationsfromotherinstitutions>
                            </v-stepper-content>
                            <v-stepper-content key="Recommendations-and-completion-time-for-consultations-received-from other-organizations-content" step="Recommendations and completion time for consultations received from other organizations">
                                <recommendationsfromotherorganizations :daterange="daterange" :requests="requests"></recommendationsfromotherorganizations>
                            </v-stepper-content>
                            <v-stepper-content key="Requests-with-Legal-Services-content" step="Requests with Legal Services">
                                <requestswithlegalservices :daterange="daterange" :requests="requests"></requestswithlegalservices>
                            </v-stepper-content>
                            <v-stepper-content key="Requests-with-Privy-Council-content" step="Requests with Privy Council">
                                <requestswithprivycouncil :daterange="daterange" :requests="requests"></requestswithprivycouncil>
                            </v-stepper-content>
                            <v-stepper-content key="Complaints-and-Investigations-content" step="Complaints and Investigations">
                                <complaintsandinvestigations :daterange="daterange" :requests="requests" :type="4"></complaintsandinvestigations>
                            </v-stepper-content>
                            <v-stepper-content key="Privacy-Impact-Assessments-(PIAs)-content" step="Privacy Impact Assessments (PIAs)">
                               <privacyimpactassessments :daterange="daterange" :requests="requests"></privacyimpactassessments>
                            </v-stepper-content>
                            <v-stepper-content key="Personal-Information-Banks-(PIBs)-content" step="Personal Information Banks (PIBs)">
                                <personalinformationbanks :daterange="daterange" :requests="requests"></personalinformationbanks>
                            </v-stepper-content>
                            <v-stepper-content key="Material-Privacy-Breaches-content" step="Material Privacy Breaches">
                                <materialprivacybreaches :daterange="daterange" :requests="requests"></materialprivacybreaches>
                            </v-stepper-content>
                            <v-stepper-content key="Costs-content" step="Costs"> 
                                <costs :daterange="daterange" :requests="requests"></costs> 
                            </v-stepper-content>
                            <v-stepper-content key="Human-Resources-content" step="Human Resources">
                                <humanresources :daterange="daterange" :requests="requests"></humanresources> 
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-col>
        </v-row>
  </v-card>
</template>

<script>
import numberofrequests from '@/components/reports/tbs/Number_of_requests_report'
import dispositionandcompletiontime from '@/components/reports/tbs/Disposition_and_completion_time_report'
import exemptions from '@/components/reports/tbs/Exemptions_report'
import exclusions from '@/components/reports/tbs/Exclusions_report'
import formatofinformationreleased from '@/components/reports/tbs/Format_of_information_released_report'
import relevantpagesprocessed from '@/components/reports/tbs/Relevant_pages_processed_report'
import relevantpagesprocessedbysize from '@/components/reports/tbs/Relevant_pages_processed_by_size_report'
import othercomplexities from '@/components/reports/tbs/Other_complexities_report'
import requestsclosedwithinlegislated from '@/components/reports/tbs/Requests_closed_within_legislated_report'
import reasonsnotmeetingstatutory from '@/components/reports/tbs/Reasons_not_meeting_statutory_report'
import requestsclosedbeyondlegislated from '@/components/reports/tbs/Requests_closed_beyond_legislated_report'
import requestsfortranslation from '@/components/reports/tbs/Requests_for_translation_report'
import disclosuresundersubsection from '@/components/reports/tbs/Disclosures_under_subsection_report'
import requestsforcorrectionofpersonal from '@/components/reports/tbs/Requests_for_correction_of_personal_information_report'
import reasonsforextensions from '@/components/reports/tbs/Reasons_for_extensions_report'
import lengthofextensions from '@/components/reports/tbs/Length_of_extensions_report'
import consultationsreceivedfromother from '@/components/reports/tbs/Consultations_received_from_other_report' 
import recommendationsfromotherinstitutions from '@/components/reports/tbs/Recommendations_from_other_institutions_report'
import recommendationsfromotherorganizations from '@/components/reports/tbs/Recommendations_from_other_organizations_report'
import requestswithlegalservices from '@/components/reports/tbs/Requests_with_legal_services_report'
import requestswithprivycouncil from '@/components/reports/tbs/Requests_with_privy_council_report'
import complaintsandinvestigations from '@/components/reports/tbs/Complaints_and_investigations_report'
import privacyimpactassessments from '@/components/reports/tbs/Privacy_impact_assessments_report'
import personalinformationbanks from '@/components/reports/tbs/Personal_information_banks_report'
import materialprivacybreaches from '@/components/reports/tbs/Material_privacy_breaches_report'
import costs from '@/components/reports/tbs/Costs_report'
import humanresources from '@/components/reports/tbs/Human_resources_report'
import { mapState,mapActions} from "vuex";
export default {
   components:{
        numberofrequests,
        dispositionandcompletiontime,
        exemptions,
        exclusions,
        formatofinformationreleased,
        relevantpagesprocessed,
        relevantpagesprocessedbysize,
        othercomplexities,
        requestsclosedwithinlegislated,
        reasonsnotmeetingstatutory,
        requestsclosedbeyondlegislated,
        requestsfortranslation,
        disclosuresundersubsection,
        requestsforcorrectionofpersonal,
        reasonsforextensions,
        lengthofextensions,
        consultationsreceivedfromother,
        recommendationsfromotherinstitutions,
        recommendationsfromotherorganizations,
        requestswithlegalservices,
        requestswithprivycouncil,
        complaintsandinvestigations,
        privacyimpactassessments,
        personalinformationbanks,
        materialprivacybreaches,
        costs,
        humanresources,
    },
  props: ['daterange'],
  data () {
    return {
        e1:'0',
        tree: [],
        active: [],
        parts : [
            { 
                id: 1, 
                name: "1. "+this.$t('Requests Under the Privacy Act'),
                title: "Requests under the Privacy Act" ,
                children: [
                    {
                        id: 11,
                        name: "1-1 Number of requests",
                        title: "Number of Requests",
                        file: "mdi-file-eye-outline"
                    },
                ]
            },
            { 
                id: 2, 
                name: "2. "+this.$t('Requests Closed During the Reporting Period'),
                title: "Requests closed during the reporting period",
                children: [
                    {
                        id: 21,
                        name: "2-1 "+this.$t('Disposition and completion time'),
                        title: "Dispostion and Completion Time",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 22,
                        name: "2-2 "+this.$t('Exemptions'),
                        title: "Exemptions",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 23,
                        name: "2-3 "+this.$t('Exclusions'),
                        title: "Exclusions",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 24,
                        name: "2-4 "+this.$t('Format of information released'),
                        title: "Format of Information Released",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 25,
                        name: "2-5 "+this.$t('Complexity'),
                        title: "Complexity",
                        children: [
                            {
                                id: 251,
                                name: "2-5-1 "+this.$t('Relevant pages processed and disclosed'),
                                title: "Relevant pages processed and disclosed",
                                file: "mdi-file-eye-outline"
                            },
                            {
                                id: 252,
                                name: "2-5-2 "+this.$t('Relevant pages processed and disclosed by size of requests'),
                                title: "Relevant pages processed and disclosed by size of requests",
                                file: "mdi-file-eye-outline"
                            },
                            {
                                id: 253,
                                name: "2-5-3 "+this.$t('Other complexities'),
                                title: "Other Complexities",
                                file: "mdi-file-eye-outline"
                            },
                        ]
                    },
                    {
                        id: 26,
                        name: "2-6 "+this.$t('Closed requests'),
                        title: "Closed requests",
                        children: [
                            {
                                id: 261,
                                name: "2-6-1 "+this.$t('Number of requests closed within legislated timelines'),
                                title: "Number of requests closed within legislated timelines",
                                file: "mdi-file-eye-outline"
                            },
                        ]
                    },
                    {
                        id: 27,
                        name: "2-7 "+this.$t('Deemed refusals'),
                        title: "Deemed Refusals",
                        children: [
                            {
                                id: 271,
                                name: "2-7-1 "+this.$t('Reasons for not meeting statutory deadline'),
                                title: "Reasons for not meeting statutory deadline",
                                file: "mdi-file-eye-outline"
                            },
                            {
                                id: 272,
                                name: "2-7-2 "+this.$t('Requests closed beyond legislated timelines'),
                                title: "Requests closed beyond legislated timelines",
                                file: "mdi-file-eye-outline"
                            },
                        ]
                    },
                    {
                        id: 28,
                        name: "2-8 "+this.$t('Requests for translation'),
                        title: "Requests for translation",
                        file: "mdi-file-eye-outline"
                    },
                ] 
            },
            
            { 
                id: 3, 
                name: "3. "+this.$t('Disclosures under subsection 8(2) and 8(5)'),
                title: "Disclosures Under Subsection 8(2) and 8(5)",
                file: "mdi-file-eye-outline" 
            },
            { 
                id: 4, 
                name: "4. "+this.$t('Requests for correction of personal information and notations'),
                title: "Request for Correction of Personal Information and Notations",
                file: "mdi-file-eye-outline" 
            },
            { 
                id: 5, 
                name: "5. "+this.$t('Extensions'),
                title: "Extensions" ,
                children: [ 
                    {
                        id: 51,
                        name: "5-1 "+this.$t('Reasons for extensions and disposition of requests'),
                        title: "Reasons for extensions and disposition of requests",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 52,
                        name: "5-2 "+this.$t('Length of extensions'),
                        title: "Length of extensions",
                        file: "mdi-file-eye-outline"
                    },
                ] 
            },
            {
                id: 6,
                name: "6. "+this.$t('Consultations Received From Other Institutions and Organizations'),
                title: "Consultations received from other institutions and organizations",
                children: [ 
                    {
                        id: 61,
                        name: "6-1 "+this.$t('Consultations received from other Government of Canada institutions and organizations'),
                        title: "Consultations received from other government institutions and organizations",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 62,
                        name: "6-2 "+this.$t('Recommendations and completion time for consultations received from other Government of Canada institutions'),
                        title: "Recommendations and completion time for consultations received from other government institutions",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 63,
                        name: "6-3 "+this.$t('Recommendations and completion time for consultations received from other organizations'),
                        title: "Recommendations and completion time for consultations received from other organizations",
                        file: "mdi-file-eye-outline"
                    },
                ] 
            },
            {
                id: 7,
                name: "7. "+this.$t('Completion Time of Consultations on Cabinet Confidences'),
                title: "Completion time of consultations on Cabinet confidences",
                children: [ 
                    {
                        id: 71,
                        name: "7-1 "+this.$t('Requests with Legal Services'),
                        title: "Requests with Legal Services",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 72,
                        name: "7-2 "+this.$t('Requests with Privy Council Office'),
                        title: "Requests with Privy Council",
                        file: "mdi-file-eye-outline"
                    },
                ] 
            },
            { 
                id: 8, 
                name: "8. "+this.$t('Complaints and investigations'),
                title: "Complaints and Investigations",
                file: "mdi-file-eye-outline" 
            },
            { 
                id: 9, 
                name: "9. "+this.$t('Privacy Impact Assessments (PIAs) and Personal Information Banks (PIBs)'),
                title: "Privacy Impact Assessments (PIAs) and Personal Information Banks (PIBs)",
                children: [ 
                    {
                        id: 91,
                        name: "9-1 "+this.$t('Privacy Impact Assessments (PIAs)'),
                        title: "Privacy Impact Assessments (PIAs)",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 92,
                        name: "9-2 "+this.$t('Personal Information Banks (PIBs)'),
                        title: "Personal Information Banks (PIBs)",
                        file: "mdi-file-eye-outline"
                    },
                ] 
            },
            { 
                id: 10, 
                name: "10. "+this.$t('Material Privacy Breaches'),
                title: "Material Privacy Breaches",
                file: "mdi-file-eye-outline" 
            },
            {
              id: 111,
              name: "11. "+this.$t('Resources related to the Privacy Act'),
              title: "Resources related to the Privacy Act",
              children: [ 
                    {
                        id: 1101,
                        name: "11-1 "+this.$t('Costs'),
                        title: "Costs",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 1102,
                        name: "11-2 "+this.$t('Human Resources'),
                        title: "Human Resources",
                        file: "mdi-file-eye-outline"
                    },
                ] 
            },
          ],
    }
  },
  mounted () {
      this.active=[this.parts[0].children[0]]
      this.loadReport(this.parts[0].children)
  },
  async created(){
      await this.retriveRequests()
  },
  computed:{ 
    ...mapState("request", {
      requests: "requests"
    }),
  },
  methods:{

    loadReport(item){
        this.e1=item[0]?item[0].title:'0'
    },
    
    ...mapActions('request', ['retriveRequests']),
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>