<template>
    <div>
    <v-row class="pa-0 ma-0">
        <v-col cols="6" :sm="12/n.length" v-for="(r, i) in n" :key="i" class="pa-0 ma-0">
            <v-simple-table class="white">
                <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                            {{item.text}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in sections.slice(r.from,r.to)"
                    :key="item"
                    >
                        <td class="my-border customoffwhite">{{ item }}</td>
                        <td class="my-border customoffwhite">{{getNOR(item)}}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
    </div>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
  },
  props: ['daterange','requests','type'],
  data () {
    return {
        n:[],
        sections:[],
        Headers:[
                { text: 'Section', value: 'section' },
                { text: this.$t('Number of requests'), value: 'nor' },
            ],
    }
  },
  async mounted () {
        if(this.type==4){
            this.sections=['18(2)','19(1)(a)','19(1)(b)','19(1)(c)','19(1)(d)','19(1)(e)','19(1)(f)','20','21','22(1)(a)(i)','22(1)(a)(ii)','22(1)(a)(iii)','22(1)(b)','22(1)(c)','22(2)','22.1','22.2','22.3','22.4','23(a)','23(b)','24(a)','24(b)','25','26','27','27.1','28'],
            this.n=[{from:0,to:9},{from:9,to:19},{from:19,to:28}];
        }
        else{
            this.sections=['13(1)(a)','13(1)(b)','13(1)(c)','13(1)(d)','13(1)(e)','14','14(a)','14(b)','15(1)','15(1) - I.A.*','15(1) - Def.*','15(1) - S.A.*','16(1)(a)(i)','16(1)(a)(ii)','16(1)(a)(iii)','16(1)(b)','16(1)(c)','16(1)(d)',
                  '16(2)','16(2)(a)','16(2)(b)','16(2)(c)','16(3)','16.1(1)(a)','16.1(1)(b)','16.1(1)(c)','16.1(1)(d)','16.2(1)','16.3','16.31','16.4(1)(a)','16.4(1)(b)','16.5','16.6','17','18(a)','18(b)','18(c)','18(d)','18.1(1)(a)',
                  '18.1(1)(b)','18.1(1)(c)','18.1(1)(d)','19(1)','20(1)(a)','20(1)(b)','20(1)(b.1)','20(1)(c)','20(1)(d)','20.1','20.2','20.4','21(1)(a)','21(1)(b)','21(1)(c)','21(1)(d)','22','22.1(1)','23','23.1','24(1)','26'];
            this.n=[{from:0,to:18},{from:18,to:35},{from:35,to:49},{from:49,to:62}];
        }
  },
  async created(){ 
        await this.retrieveLayerBoxes();
        await this.retrieveLayers();
        await this.retrieveDocuments();
  },
  computed:{  
    ...mapGetters('documents', {
      'layerboxes':'getLayerBoxes','layers':'getLayers','documents':'getDocuments'
    }),
  },
  methods: {
    getNOR(section){
        let result;
        let r1=this.layerboxes.filter(item => item.clausesUsed.includes(section)).map(i=>i.layer_id);
        let r2=this.layers.filter(item => r1.includes(item.id)).map(i=>i.document_id);
        let r3=this.documents.filter(item=> r2.includes(item.id)).map(i=>i.request_id);
        result=this.requests.filter(item=> r3.includes(item.id) &&
                                    new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() &&
                                    item.DateOfClosure!=null)
        return result.length 
    },
    ...mapActions("documents", ["retrieveLayerBoxes","retrieveLayers","retrieveDocuments"]),
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>