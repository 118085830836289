<template>
    <v-row class="pa-0 ma-0">
        <v-col cols="6" sm="4" v-for="(r, i) in n" :key="i" class="pa-0 ma-0">
            <v-simple-table class="white">
                <template v-slot:default>
                <thead>
                    <tr>
                        <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                            {{item.text}}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                    v-for="item in sections.slice(r.from,r.to)"
                    :key="item"
                    >
                        <td class="my-border customoffwhite">{{ item }}</td>
                        <td class="my-border customoffwhite">{{getNOR(item)}}</td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>
        </v-col>
    </v-row>
</template>

<script>
import { mapActions,mapGetters } from "vuex";
import moment from "moment";
export default {
  components: {
  },
  props: ['daterange','requests','type'],
  data () {
    return {
        n:[],
        Headers:[
                { text: 'Section', value: 'section' },
                { text: this.$t('Number of requests'), value: 'nor' },
            ],
        sections:[],
    }
  },
  mounted () {
        if(this.type==4){
            this.sections=[
                '69(1)(a)','69(1)(b)','69.1','70(1)','70(1)(a)','70(1)(b)','70(1)(c)',
                '70(1)(d)','70(1)(e)','70(1)(f)','70.1'
            ];
            this.n=[{from:0,to:3},{from:3,to:7},{from:7,to:11}];
        }
        else{
            this.sections=[
                '68(a)','68(b)','68(c)','68.1','68.2(a)','68.2(b)','69(1)','69(1)(a)',
                '69(1)(b)','69(1)(c)','69(1)(d)','69(1)(e)','69(1)(f)','69(1)(g) re (a)','69(1)(g) re (b)',
                '69(1)(g) re (c)','69(1)(g) re (d)','69(1)(g) re (e)','69(1)(g) re (f)','69.1(1)'
            ];
            this.n=[{from:0,to:6},{from:6,to:13},{from:13,to:20}];
        }
  },
  async created(){ 
        await this.retrieveLayerBoxes();
        await this.retrieveLayers();
        await this.retrieveDocuments();
  },
  computed:{  
    ...mapGetters('documents', {
      'layerboxes':'getLayerBoxes','layers':'getLayers','documents':'getDocuments'
    }),
  },
  methods: {
       getNOR(section){
        let result;
        let r1=this.layerboxes.filter(item => item.clausesUsed.includes(section)).map(i=>i.layer_id);
        let r2=this.layers.filter(item => r1.includes(item.id)).map(i=>i.document_id);
        let r3=this.documents.filter(item=> r2.includes(item.id)).map(i=>i.request_id);
        result=this.requests.filter(item=> r3.includes(item.id) &&
                                    new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() >= new Date(moment(this.daterange[0]).format("YYYY-MM-DD")).getTime() && 
                                    new Date(moment(this.daterange[1]).format("YYYY-MM-DD")).getTime() >= new Date(moment(item.DateOfClosure).format("YYYY-MM-DD")).getTime() &&
                                    item.DateOfClosure!=null)
        return result.length 
    },
    ...mapActions("documents", ["retrieveLayerBoxes","retrieveLayers","retrieveDocuments"]),
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>