import { render, staticRenderFns } from "./Recommendations_from_other_organizations_report.vue?vue&type=template&id=1f2b4ecc&scoped=true&"
import script from "./Recommendations_from_other_organizations_report.vue?vue&type=script&lang=js&"
export * from "./Recommendations_from_other_organizations_report.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f2b4ecc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
