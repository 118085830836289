<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"    
            :key="item.exp"
            >     
                <td v-if="item.exp[0]=='-'" class="my-border text-center"></td>
                <td class="my-border customoffwhite">{{ item.exp }}</td>
                <td v-if="item.exp[0]!='-'" class="my-border text-center"></td>
                <td class="my-border customoffwhite">${{ item.amount }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
            {text:this.$t('Expenditures'),value:'exp'},
            {text:'',value:'sub'},
            {text:this.$t('Amount'),value:'amount'}, 
        ],
        List:[
                {exp:this.$t('Salaries'),amount:'0.00'},
                {exp:this.$t('Overtime'),amount:'0.00'},
                {exp:this.$t('Goods and Services'),amount:'0.00'},
                {exp:this.$t('- Professional services contracts'),amount:'0.00'},
                {exp:this.$t('- Other'),amount:'0.00'},
                {exp:this.$t('Total'),amount:'0.00'},
        ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>