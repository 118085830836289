<template>
    <div>
      <v-row style="margin-bottom:22px;">
        <v-col cols="5">
          <v-img
            height="auto"
            width="auto"
            style="display:block;"
            :src="require('@/assets/gov-of-canada.jpg')"
          ></v-img>
        </v-col>
      </v-row>
        <center style="margin-top:20px; margin-bottom:20px;"><h4 class="text--secondary headline">{{$t("Statistical Report on the Privacy - Validation")}}</h4></center>
        <h5 class="subtitle-1" style="margin-top:20px;"><span class="text--secondary">{{$t("Name of institution")}}:</span>  <b class="text--primary"> SoftSim Co.</b></h5>
        <h5 class="subtitle-1" style="margin-top:20px;">
          <span class="text--secondary">{{$t("Reporting period")}}: </span> 
          <span class="my-border text--primary" style="margin:4px;padding:8px">{{daterange[0]}}</span>
          <span class="text--secondary"> {{$t("to")}} </span>  
          <span class="my-border text--primary" style="margin:4px;padding:8px">{{daterange[1]}}</span>
        </h5>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 1: {{$t("Requests Under the Privacy - Validation")}}</span></div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">1.1 {{$t("Number of requests")}}</h5></div>
            <numberofrequests :daterange="daterange" :requests="requests"></numberofrequests>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">1.2 {{$t("Sources of requests")}}</h5></div>
            <sourcesofrequests :daterange="daterange" :requests="requests"></sourcesofrequests>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">1.3 {{$t("Informal requests")}}</h5></div>
            <informalrequests :daterange="daterange" :requests="requests"></informalrequests>
          </div>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 2: {{$t("Requests Closed During the Reporting Period")}}</span></div>	
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">2.1 {{$t("Disposition and completion time")}}</h5></div>
            <dispositionandcompletiontime :daterange="daterange" :requests="requests" :type="0"></dispositionandcompletiontime>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"> <h5 class="text--primary">2.2 {{$t("Exemptions")}}</h5></div>
            <exemptions :daterange="daterange" :requests="requests" :type="3"></exemptions>
          </div>
          <div style="margin-top:100px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.3 {{$t("Exclusions")}}</h5></div>
            <exclusions :daterange="daterange" :requests="requests" :type="3"></exclusions>		
          </div>	
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.4 {{$t("Format of information released")}}</h5></div>
            <formatofinformationreleased :daterange="daterange" :requests="requests"></formatofinformationreleased>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.5 {{$t("Complexity")}}</h5></div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.5.1 {{$t("Relevant pages processed and disclosed by size of requests")}}</h5></div>
              <relevantpagesprocessedbysize :daterange="daterange" :requests="requests"></relevantpagesprocessedbysize>
            </div>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.6 {{$t("Deemed refusals")}}</h5></div>
            <div style="margin-top:20px">
              <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 style="margin-left:20px;">2.6.1 {{$t("Reasons for not meeting legislated timelines")}}</h5></div>
              <reasonsnotmeetingstatutory :daterange="daterange" :requests="requests"></reasonsnotmeetingstatutory>
            </div>
          </div>
          <div style="margin-top:20px">
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">2.7 {{$t("Requests for translation")}}</h5></div>
            <requestsfortranslation :daterange="daterange" :requests="requests"></requestsfortranslation>	
          </div>	
        </div>	
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 3: {{$t("Disclosures under subsection 8(2) and 8(5)")}}</span></div>	
          <disclosuresundersubsection :daterange="daterange" :requests="requests"></disclosuresundersubsection>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 4: {{$t("Requests for correction of personal information and notations")}}</span></div>	
          <requestsforcorrectionofpersonal :daterange="daterange" :requests="requests"></requestsforcorrectionofpersonal>
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 5: {{$t("Extensions")}}</span></div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">5.1 {{$t("Reasons for extensions and disposition of requests")}}</h5></div>
            <reasonsforextensions :daterange="daterange" :requests="requests"  :type="3"></reasonsforextensions>
          </div>		
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">5.2 {{$t("Length of extensions")}}</h5></div>
            <lengthofextensions :daterange="daterange" :requests="requests"  :type="3"></lengthofextensions>
          </div>					
        </div>
        <div style="margin-top:20px">
          <div class="subtitle-1" style="background:rgb(74, 85, 104);color:white; margin-bottom:20px; margin-top:40px; padding:4px;"><span>Section 6: {{$t("Consultations Received From Other Institutions and Organizations")}}</span></div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">6.1 {{$t("Consultations received from other Government of Canada institutions and organizations")}}</h5></div>
            <consultationsreceivedfromother :daterange="daterange" :requests="requests"></consultationsreceivedfromother>
          </div>		
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">6.2 {{$t("Recommendations and completion time for consultations received from other Government of Canada institutions")}}</h5></div>
            <recommendationsfromotherinstitutions :daterange="daterange" :requests="requests"></recommendationsfromotherinstitutions>
          </div>	
          <div style="margin-top:20px">	
            <div class="subtitle-1" style="color:rgba(0, 0, 0, 0.6) !important;  margin-bottom:20px;"><h5 class="text--primary">6.3 {{$t("Recommendations and completion time for consultations received from other organizations")}}</h5></div>
            <recommendationsfromotherorganizations :daterange="daterange" :requests="requests"></recommendationsfromotherorganizations>
          </div>					
        </div>
    </div>
</template>

<script>
import numberofrequests from '@/components/reports/tbs/Number_of_requests_report'
import sourcesofrequests from '@/components/reports/tbs/Sources_of_requests_report'
import informalrequests from '@/components/reports/tbs/Informal_requests_report'
import dispositionandcompletiontime from '@/components/reports/tbs/Disposition_and_completion_time_report'
import exemptions from '@/components/reports/tbs/Exemptions_report'
import exclusions from '@/components/reports/tbs/Exclusions_report'
import formatofinformationreleased from '@/components/reports/tbs/Format_of_information_released_report' 
import relevantpagesprocessedbysize from '@/components/reports/tbs/Relevant_pages_processed_by_size_report'
import reasonsnotmeetingstatutory from '@/components/reports/tbs/Reasons_not_meeting_statutory_report'
import requestsfortranslation from '@/components/reports/tbs/Requests_for_translation_report'
import disclosuresundersubsection from '@/components/reports/tbs/Disclosures_under_subsection_report'
import requestsforcorrectionofpersonal from '@/components/reports/tbs/Requests_for_correction_of_personal_information_report'
import reasonsforextensions from '@/components/reports/tbs/Reasons_for_extensions_report'
import lengthofextensions from '@/components/reports/tbs/Length_of_extensions_report'
import consultationsreceivedfromother from '@/components/reports/tbs/Consultations_received_from_other_report' 
import recommendationsfromotherinstitutions from '@/components/reports/tbs/Recommendations_from_other_institutions_report'
import recommendationsfromotherorganizations from '@/components/reports/tbs/Recommendations_from_other_organizations_report'
import { mapState,mapActions} from "vuex";
export default {
   components:{
        numberofrequests,
        sourcesofrequests,
        informalrequests,
        dispositionandcompletiontime,
        exemptions,
        exclusions,
        formatofinformationreleased, 
        relevantpagesprocessedbysize,
        reasonsnotmeetingstatutory,
        requestsfortranslation,
        disclosuresundersubsection,
        requestsforcorrectionofpersonal,
        reasonsforextensions,
        lengthofextensions,
        consultationsreceivedfromother,
        recommendationsfromotherinstitutions,
        recommendationsfromotherorganizations,
    },
  props: ['daterange'],
  data () {
    return {
    }
  },
  mounted () {
  },  
  async created(){
      await this.retriveRequests()
  },
  computed:{ 
    ...mapState("request", {
      requests: "requests"
    }),
  },
  methods:{

    loadReport(item){
        this.e1=item[0]?item[0].title:'0'
    },
    
    ...mapActions('request', ['retriveRequests']),
  },
  watch: {
  }
}
</script>
<style type="text/css" media="print">
@media print {
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact !important;  
    forced-color-adjust: exact !important;
    overflow: auto;
    height: auto; 
  }
}
@page {
    size: auto; 
    margin-top: 0;
    margin-bottom: 0;
    margin-left:5px;
    margin-right:5px;
}
</style>
