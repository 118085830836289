<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr>
                    <th></th>
                    <th class="text-left my-border customlightgray">
                        {{$t("Number of requests")}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.name"
                >
                    <td class="my-border customoffwhite">{{ item.name }}</td>
                    <td class="my-border customoffwhite">{{item.nofr}}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
         
        List:[
            {name:this.$t('Number of requests'),nofr:'0'},
            {name:this.$t('Outstanding from the previous reporting period'),nofr:'0'},
            {name:this.$t('Total'),nofr:'0'},
            {name:this.$t('Closed during the reporting period'),nofr:'0'},
            {name:this.$t('Carried over to next reporting period'),nofr:'0'},
        ],
            
    }
  },
  mounted () {
  },
  async created(){
  },
  computed:{ 
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>