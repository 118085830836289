<template>
<v-card class="pa-0 ma-0 elevation-0 white" style="max-height: 100%">
        <v-row class="pa-0 ma-0">
            <v-col cols="12" md="4" class="pa-0 ma-0 ">
                <v-row class="pa-0 ma-0">
                    <v-col cols="12" md="11" class="pa-0 ma-0">
                        <v-card-text class="pa-0 ma-0">
                            <v-treeview
                                v-model="tree"
                                :items="parts"
                                item-key="id"
                                activatable
                                :active.sync="active"
                                open-on-click
                                return-object
                                expand-icon="mdi-chevron-down"
                                @update:active="loadReport"
                            >
                                <template v-slot:prepend="{ item, open }" >
                                    <v-icon v-if="!item.file">
                                        {{ open ? 'mdi-clipboard-file-outline' : 'mdi-clipboard-file' }}
                                    </v-icon>
                                    <v-icon v-else>
                                    {{item.file}}
                                    </v-icon>
                                </template>
                            </v-treeview>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="1" class="text-center pa-0 ma-0"><v-divider class="d-none d-md-block pa-0 ma-auto" vertical></v-divider><v-divider class="d-block d-md-none pa-0 ma-auto"></v-divider></v-col>
                </v-row>
            </v-col>
            <v-col cols="12" md="8" class="pa-0 ma-0">
                <v-card-text class="pa-0 ma-0">
                    <v-stepper v-model="e1" class="elevation-0">
                        <v-stepper-items class="white">
                            <v-stepper-content key="Number-of-Requests-content" step="Number of Requests">
                                <numberofrequests :daterange="daterange" :requests="requests"></numberofrequests>
                            </v-stepper-content>
                            <v-stepper-content key="Sources-of-Requests-content" step="Sources of Requests">
                                <sourcesofrequests :daterange="daterange" :requests="requests"></sourcesofrequests>
                            </v-stepper-content>
                            <v-stepper-content key="Dispostion-and-Completion-Time-content" step="Dispostion and Completion Time">
                                <dispositionandcompletiontime :daterange="daterange" :requests="requests" :type='1'></dispositionandcompletiontime>
                            </v-stepper-content>
                        </v-stepper-items>
                    </v-stepper>
                </v-card-text>
            </v-col>
        </v-row>
  </v-card>
</template>

<script>
import numberofrequests from '@/components/reports/tbs/Number_of_requests_report'
import sourcesofrequests from '@/components/reports/tbs/Sources_of_requests_report'
import dispositionandcompletiontime from '@/components/reports/tbs/Disposition_and_completion_time_report'
import { mapState,mapActions} from "vuex";
export default {
   components:{
        numberofrequests,
        sourcesofrequests,
        dispositionandcompletiontime,
    },
  props: ['daterange'],
  data () {
    return {
        e1:'0',
        tree: [],
        active: [],
        parts : [
            { 
                id: 1, 
                name: "1. "+this.$t('Requests Under the Access to Information Act'),
                title: "Requests under the Access to Information Act" ,
                children: [
                    {
                        id: 11,
                        name: "1-1 "+this.$t('Number of requests'),
                        title: "Number of Requests",
                        file: "mdi-file-eye-outline"
                    },
                    {
                        id: 12,
                        name: "1-2 "+this.$t('Sources of requests'),
                        title: "Sources of Requests",
                        file: "mdi-file-eye-outline"
                    },
                ]
            },
            { 
                id: 2, 
                name: "2. "+this.$t('Requests Closed During the Reporting Period'),
                title: "Requests closed during the reporting period",
                children: [
                    {
                        id: 21,
                        name: "2-1 "+this.$t('Disposition and completion time'),
                        title: "Dispostion and Completion Time",
                        file: "mdi-file-eye-outline"
                    },    
                ]
            },
          ],
    }
  },
  mounted () {
      this.active=[this.parts[0].children[0]]
      this.loadReport(this.parts[0].children)
  },
  async created(){
      await this.retriveRequests()
  },
  computed:{ 
    ...mapState("request", {
      requests: "requests"
    }),
  },
  methods:{

    loadReport(item){
        this.e1=item[0]?item[0].title:'0'
    },
    
    ...mapActions('request', ['retriveRequests']),
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>