<template>
    <v-simple-table class="white">
        <template v-slot:default>
            <thead>
                <tr>
                    <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                        {{item.text}}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                v-for="item in List"
                :key="item.dispo"
                >
                    <td class="my-border customoffwhite">{{ item.dispo }}</td>
                    <td class="my-border customoffwhite">{{ item.num }}</td>
                </tr>
            </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
                { text: this.$t('Disposition for Correction Requests Received'), value: 'dispo' },
                { text: this.$t('Number'), value: 'num' },
            ],
        List:[
                {dispo:this.$t('Notations attached'),num:'0'},
                {dispo:this.$t('Requests for correction accepted'),num:'0'},
                {dispo:this.$t('Total'),num:'0'}
            ],
    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>