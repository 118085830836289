import { render, staticRenderFns } from "./Number_of_requests_report.vue?vue&type=template&id=7f6e8d1e&scoped=true&"
import script from "./Number_of_requests_report.vue?vue&type=script&lang=js&"
export * from "./Number_of_requests_report.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f6e8d1e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
installComponents(component, {VSimpleTable})
