<template>
    <v-simple-table class="white">
        <template v-slot:default>
        <thead>
            <tr>
                <th v-for="item in Headers" :key="item.value" class="text-left my-border customlightgray">
                    {{item.text}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr
            v-for="item in List"
            :key="item.trans"
            >
                <td class="my-border customoffwhite">{{ item.trans }}</td>
                <td class="my-border customoffwhite">{{ item.acc }}</td>
                <td class="my-border customoffwhite">{{ item.ref }}</td>
                <td class="my-border customoffwhite">{{ item.total }}</td>
            </tr>
        </tbody>
        </template>
    </v-simple-table>
</template>

<script>
export default {
  components: {
  },
  props: ['daterange','requests'],
  data () {
    return {
        Headers:[
            {text:this.$t('Translation Requests'),value:'trans'},
            {text:this.$t('Accepted'),value:'acc'},
            {text:this.$t('Refused'),value:'ref'},
            {text:this.$t('Total'),value:'total'},
        ],
        List:[
            {trans :this.$t('English to French'),acc:'0',ref:'0',total:'0'},
            {trans :this.$t('French to English'),acc:'0',ref:'0',total:'0'},
            {trans :this.$t('Total'),acc:'0',ref:'0',total:'0'},
        ],

    }
  },
  mounted () {
  },
  async created(){ 
  },
  computed:{  
  },
  methods: {
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
</style>