<template>
  <v-card
    class="pa-0 ma-0 elevation-0 white"
    style="max-height: 100%"
  >
      <v-card-title class="py-0 my-0 my-border customlightgray">
      <v-row
        class="ma-0 pa-0 justify-center text-center text-md-left text-lg-left">
        <v-col cols="12" class="px-0 ma-0">
          <div class="font-weight-light pa-0 ma-0">
                    <v-autocomplete
                    class="ma-0 pa-0 transparent font-weight-light elevation-0"
                    :items="reports"
                    @click:clear="clearParts"
                    clearable
                    v-model="selectedReport"
                    item-value="id"
                    :label="$t('Select Annual Report Request')"
                    @change="selectReport"
                    dense
                    outlined
                    hide-details
                    >
                      <template slot="selection" slot-scope="data">
                       {{$t(data.item.label)}}
                      </template>
                      <template slot="item" slot-scope="data">
                        {{$t(data.item.label)}}
                      </template>
                    </v-autocomplete>
         </div>
        </v-col>
        
        <v-expand-transition>
            <v-col v-show="selected!=null" cols="12" class="pa-0 pb-2 ma-0">
                    <v-menu
                        class="ma-0 pa-0"
                        :close-on-content-click="false"
                        :nudge-right="30"
                        transition="scale-transition"
                        offset-y
                            dark
                        small-chips
                        min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-combobox
                            v-model="daterange"
                            multiple
                            clearable
                            @click:clear="clear"
                            small-chips
                            class="justify-center d-flex"
                            :label="$t('daterange')"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        >
                          <template v-slot:selection="data" class="pa-0 ma-0">
                              <v-chip :input-value="data.selected" small class="custom-color-accent">
                                  <strong>{{ data.item }}</strong>
                              </v-chip>
                          </template>
                        </v-combobox>
                        </template>
                        <v-date-picker
                        range
                        v-model="daterange"
                        >
                          <v-autocomplete :items="dates" v-model="selectedDate" :label="$t('select fiscal year')" :placeholder="$t('select fiscal year')" dense outlined hide-details clearable></v-autocomplete>
                        </v-date-picker>
                    </v-menu>
            </v-col>
        </v-expand-transition>
      </v-row>
    </v-card-title>
  </v-card>
</template>
<script>
import moment from 'moment'
export default {
  components: {},
  data: () => ({
    selected:null,
    selectedDate:null,
    daterange:[],
    dates:[],
    reports: [
      {
        id: 0,
        label: "Access to information Act",
      },
      {
        id: 1,
        label: "Access to information Act w off",
      },
      {
        id: 2,
        label: "ATI - Validation",
      },
      {
        id: 3,
        label: "Privacy - Validation",
      },
      {
        id: 4,
        label: "Privacy act",
      },
    ],
    selectedReport: "",
  }),
  created(){
    let thisyear=moment().year()
    let thismonth=moment().month()
    for(let i=thismonth>2?thisyear+1:thisyear;i>2000;i--){
      this.dates.push(i-1+'-'+i)
    }
  },
  watch:{
    selectedDate(val){
      if(val){
        let range = val.split('-');
        let startd=range[0]+'-04-01';
        let endd=range[1]+'-03-31';
        this.daterange=[startd,endd]
      }
      else{
        this.daterange[0]=moment().format('YYYY-MM-DD');
        this.daterange[1]=moment().format('YYYY-MM-DD');
      }
    },
    selectedReport(val){
      this.selectReport(val);
      if(this.daterange.length>1 && this.selected!=null)
        this.applySelection();
      else
        this.clear()
    },
    daterange(val){
      if(val.length>1 && this.selected!=null)
        this.applySelection();
      else
        this.clear();
    }
  },
  methods: {
    clearParts(){
        this.parts=[];
        this.daterange=[];
        this.$emit("setType",this.selected)
        this.$emit("setShow",false)
        this.$emit("setDateRange",this.daterange)
    },
    clear(){
        this.$emit("setShow",false)
    },
    applySelection(){
        this.$emit("setType",this.selected)
        this.$emit("setShow",true)
        this.$emit("setDateRange",this.daterange)
    },
    selectReport(id) {
        this.selected=id;
      
    },
  },
};
</script>